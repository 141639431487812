import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useFavoriteDevicesCollection } from '../app/DBProvider'
import FavoriteDeviceInfoTab from './FavoriteDeviceInfoTab'

const tabValues = ['info']

export default function FavoriteDevicePage() {
  const { favoriteDeviceId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const favoriteDevicesCollection = useFavoriteDevicesCollection()
  const { result: favoriteDevices, isFetching } = useRxQuery(favoriteDevicesCollection.findOne(favoriteDeviceId))

  if (isFetching) return <></>

  const favoriteDevice = favoriteDevices?.[0]
  if (!favoriteDevice) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {favoriteDevice.name}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/favorite-devices/${favoriteDeviceId}`} />
      </Tabs>
      <Box sx={{ p: 2 }}>{tabIndex === 0 && <FavoriteDeviceInfoTab favoriteDevice={favoriteDevice} />}</Box>
    </>
  )
}
