import { FavoriteDevice } from '@/lib/schemas/FavoriteDeviceSchema'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function FavoriteDeviceInfoTab({ favoriteDevice }: Readonly<{ favoriteDevice: FavoriteDevice }>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Field</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">ID</TableCell>
          <TableCell>{favoriteDevice.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell>{favoriteDevice.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Equipment Type ID</TableCell>
          <TableCell>{favoriteDevice.equipmentTypeId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Description</TableCell>
          <TableCell>{favoriteDevice.description}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Order Number</TableCell>
          <TableCell>{favoriteDevice.orderNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Pin Color</TableCell>
          <TableCell>{favoriteDevice.pinColor}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Budgeted Cost</TableCell>
          <TableCell>{favoriteDevice.budgetedCost}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
