import { DeepReadonlyObject } from 'rxdb'
import { ServiceTicketComment } from '@/lib/schemas/ServiceTicketCommentSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { User } from '@/lib/schemas/UserSchema'
import _ from 'lodash'
import AppDataGrid from '@/components/lib/AppDataGrid'

interface ServiceTicketCommentsProps {
  comments: DeepReadonlyObject<ServiceTicketComment>[]
  users: DeepReadonlyObject<User>[]
}

export default function ServiceTicketCommentsTable({ comments, users }: Readonly<ServiceTicketCommentsProps>) {
  const columns: GridColDef[] = [
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 2,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'createdDate',
      headerName: 'Created At',
      flex: 1,
    },
  ]

  const rows = _.map(comments, (comment) => ({
    id: comment.id,
    comment: comment.comment,
    createdBy: _.find(users, (user) => user.id === comment.createdByUserId)?.name ?? 'N/A',
    createdDate: comment.createdDate ? new Date(comment.createdDate).toISOString() : 'N/A',
  }))

  return <AppDataGrid rows={rows} columns={columns} emptyMessage="No comments" />
}
