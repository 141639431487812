import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { DeepReadonlyObject } from 'rxdb'

export default function ServiceTicketDevicesTab({
  serviceTicket,
}: Readonly<{
  serviceTicket: DeepReadonlyObject<ServiceTicket>
}>) {
  console.log('serviceTicket', serviceTicket)
  return <>todo </>
}
