import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import LocationInfoTab from './LocationInfoTab'
import { useLocationsCollection } from '../app/DBProvider'

const tabValues = ['info']

export default function LocationPage() {
  const { accountId, locationId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId ?? '') : 0
  const locationsCollection = useLocationsCollection()

  const { result: locations, isFetching } = useRxQuery(locationsCollection.findOne(locationId ?? ''))
  const location = locations?.[0]

  if (isFetching) return <></>

  if (!location) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {location.imageLeft} x {location.imageTop}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/accounts/${accountId}/locations/${locationId}`} />
      </Tabs>
      <Box sx={{ p: 2 }}>{tabIndex === 0 && <LocationInfoTab location={location} />}</Box>
    </>
  )
}
