import { useRxQuery } from 'rxdb-hooks'
import { useTaskImagesCollection } from '../app/DBProvider'
import { Task } from '@/lib/schemas/TaskSchema'
import { TaskImage } from '@/lib/schemas/TaskImageSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'

export default function TaskImagesTab({ task }: Readonly<{ task: Task }>) {
  const taskImagesCollection = useTaskImagesCollection()
  const { result: images, isFetching } = useRxQuery(taskImagesCollection.find({ selector: { taskId: task.id } }))

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Images</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {images?.map((image: TaskImage) => {
          return (
            <TableRow key={image.id}>
              <TableCell>
                <Link>{image.defaultViewFilePath}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
