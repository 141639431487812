import Breadcrumbs from '../layout/Breadcrumbs'
import DashboardPage from '../dashboard/DashboardPage'

export default function AccountDashboardPage() {
  return (
    <>
      <Breadcrumbs />
      <div>
        <DashboardPage />
      </div>
    </>
  )
}
