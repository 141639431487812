import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-premium'
import React from 'react'

export default function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}
