import { Box, Typography } from '@mui/material'

export default function MissingPlanTypeScreen() {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      <Typography variant="h5" gutterBottom>
        Missing Plan
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Your organization does not have a current plan. Please contact support@siteowl.com.
      </Typography>
    </Box>
  )
}
