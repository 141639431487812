import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import { useBreakpoints } from '@/lib/useBreakpoints'

export default function NavItem({
  label,
  icon,
  href,
  mobileOpen,
  handleDrawerToggle,
  onClick,
}: Readonly<{
  label: string
  icon: IconDefinition
  href?: string
  mobileOpen: boolean
  handleDrawerToggle: () => void
  onClick?: () => void
}>) {
  const location = useLocation()
  const isActive = href ? location.pathname === href : false
  const { isSmallScreen } = useBreakpoints()

  const handleClick = () => {
    if (isSmallScreen) {
      handleDrawerToggle()
    }
    if (onClick) {
      onClick()
    }
  }

  const listItemButton = (
    <ListItemButton
      {...(href ? { href } : {})}
      selected={isActive}
      onClick={handleClick}
      sx={{
        minHeight: 48,
        justifyContent: mobileOpen ? 'initial' : 'right',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 20,
          mr: mobileOpen ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText primary={label} sx={{ opacity: mobileOpen ? 1 : 0 }} />
    </ListItemButton>
  )

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      {!mobileOpen ? (
        <Tooltip title={label} placement="right" arrow>
          {listItemButton}
        </Tooltip>
      ) : (
        listItemButton
      )}
    </ListItem>
  )
}
