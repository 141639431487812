import { Box, Typography, Button, useTheme, IconButton, Menu, MenuItem, Link } from '@mui/material'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import NewServiceTicketModal from './NewServiceTicketModal'
import AccountServiceTicketsTable from './AccountServiceTicketsTable '
import { GridColDef } from '@mui/x-data-grid-premium'
import { RxDocument } from 'rxdb'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import Breadcrumbs from '../layout/Breadcrumbs'

export default function AccountServiceTicketsPage() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateServiceTicket = (serviceTicket: any) => {
    // Navigate to the newly created serviceTicket's page
    navigate(`/accounts/${serviceTicket.accountId}/service-tickets/${serviceTicket.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewServiceTicketClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  const formatStatus = (status: string) => {
    return status
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const formatDueDate = (dueDate: string | undefined) => {
    if (!dueDate) return ''
    const date = new Date(dueDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const columns: GridColDef<RxDocument<ServiceTicket>>[] = [
    {
      field: 'id',
      headerName: 'Ticket #',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Link href={`/accounts/${accountId}/service-tickets/${params.row.id}`} variant="body1">
            {params.row.id}
          </Link>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 1 }}>
            <Link href={`/accounts/${accountId}/service-tickets/${params.row.id}`} variant="body2">
              {params.row.subject}
            </Link>
          </Box>
        </Box>
      ),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 2,
      renderCell: (params) => (
        <Link href={`/accounts/${accountId}/service-tickets/${params.row.id}`} variant="body2">
          {params.row.subject}
        </Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <Typography variant="body2">{formatStatus(params.row.status)}</Typography>,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      renderCell: (params) => <Typography variant="body2">{formatDueDate(params.row.dueDate)}</Typography>,
    },
    {
      field: 'site',
      headerName: 'Site',
      flex: 1,
      renderCell: (params) => {
        const site = (params.row as any).site
        return site ? (
          <Link href={`/accounts/${accountId}/sites/${site.id}`} variant="body2">
            {site.name}
          </Link>
        ) : null
      },
    },
    {
      field: 'building',
      headerName: 'Building',
      flex: 1,
      renderCell: (params) => {
        const building = (params.row as any).building
        return building ? (
          <Link href={`/accounts/${accountId}/sites/${building.siteId}/buildings/${building.id}`} variant="body2">
            {building.name}
          </Link>
        ) : null
      },
    },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 1,
      renderCell: (params) => {
        const floor = (params.row as any).floor
        return floor ? (
          <Link
            href={`/accounts/${accountId}/sites/${floor.siteId}/buildings/${floor.buildingId}/floors/${floor.id}`}
            variant="body2"
          >
            {floor.name}
          </Link>
        ) : null
      },
    },
  ]

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">Service Tickets</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Service Tickets shows all of the Service Tickets that are associated with the Live Site.{' '}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleOpenModal}
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
              whiteSpace: 'nowrap',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
            }}
          >
            New Service Ticket
          </Button>
          <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
            <IconButton onClick={handleMenuOpen}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleMenuNewServiceTicketClick}>New ServiceTicket</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <AccountServiceTicketsTable columns={columns} />
      <NewServiceTicketModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateServiceTicket}
        accountId={accountId}
      />
    </>
  )
}
