import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  MenuItem,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ServiceTicketStatus, ServiceTicketPriority, ServiceTicketDocument } from '@/lib/schemas/ServiceTicketSchema'

interface ServiceTicketEditModalProps {
  open: boolean
  onClose: () => void
  serviceTicket: ServiceTicketDocument
}

export default function ServiceTicketEditModal({
  open,
  onClose,
  serviceTicket,
}: Readonly<ServiceTicketEditModalProps>) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [formData, setFormData] = useState({ ...serviceTicket })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    // Handle save logic here
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isPhone}>
      <DialogTitle>
        Edit Service Ticket
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 500 }}>
          <TextField label="Subject" name="subject" value={formData.subject} onChange={handleChange} fullWidth />
          <TextField select label="Status" name="status" value={formData.status} onChange={handleChange} fullWidth>
            {Object.values(ServiceTicketStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status.replace('_', ' ')}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Priority"
            name="priority"
            value={formData.priority}
            onChange={handleChange}
            fullWidth
          >
            {Object.values(ServiceTicketPriority).map((priority) => (
              <MenuItem key={priority} value={priority}>
                {priority}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            label="Due Date"
            name="dueDate"
            type="datetime-local"
            value={formData.dueDate ? formData.dueDate.slice(0, 16) : ''}
            onChange={handleChange}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
          <TextField
            label="Scheduled Date"
            name="scheduledDate"
            type="datetime-local"
            value={formData.scheduledDate ? formData.scheduledDate.slice(0, 16) : ''}
            onChange={handleChange}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
          <TextField
            label="Cost"
            name="cost"
            type="number"
            value={formData.cost || ''}
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
