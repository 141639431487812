import { useRxQuery } from 'rxdb-hooks'
import { useServiceTicketFileAttachmentsCollection, useUsersCollection } from '../app/DBProvider'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { DeepReadonlyObject } from 'rxdb'
import React from 'react'
import ServiceTicketFilesTable from '@/components/serviceTicket/components/ServiceTicketFilesTable'
import _ from 'lodash'

export default function ServiceTicketFilesTab({
  serviceTicket,
}: Readonly<{ serviceTicket: DeepReadonlyObject<ServiceTicket> }>) {
  const serviceTicketFileAttachmentsCollection = useServiceTicketFileAttachmentsCollection()
  const usersCollection = useUsersCollection()

  const { result: fileAttachments, isFetching } = useRxQuery(
    serviceTicketFileAttachmentsCollection.find({ selector: { serviceTicketId: serviceTicket.id } }),
  )

  const userIds = _.map(fileAttachments, (file) => file.uploadedByUserId)
  const { result: users, isFetching: isFetchingUsers } = useRxQuery(
    usersCollection.find({ selector: { id: { $in: userIds } } }),
  )

  if (isFetching || isFetchingUsers) return <></>

  return <ServiceTicketFilesTable files={fileAttachments} users={users} />
}
