import { PluginListenerHandle, registerPlugin } from '@capacitor/core'
import { FronteggState } from '@frontegg/ionic-capacitor'
import { FronteggNativePlugin } from '@frontegg/ionic-capacitor/dist/esm/definitions'
import { useEffect } from 'react'
import { useFronteggAuthState } from './FronteggUserProvider'

export const FronteggNative = registerPlugin<FronteggNativePlugin>('FronteggNative')

export default function FronteggUserProviderNative({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const { setFronteggAuth } = useFronteggAuthState()

  useEffect(() => {
    let unsubscriber: PluginListenerHandle | undefined
    const initializeFronteggAuthState = async () => {
      console.log('FEUPNative: initializeFronteggAuthState')
      // listen for authenticated Frontegg user
      unsubscriber = await FronteggNative.addListener('onFronteggAuthEvent', (state: FronteggState) => {
        console.log(`FEUPNative: onFronteggAuthEvent`, state.user?.id)
        setFronteggAuth({
          fronteggUserId: state.user?.id,
          fronteggTenantId: state.user?.tenantId,
          fronteggTenantIds: state.user?.tenantIds,
          fronteggAccessToken: state.accessToken ?? undefined,
          fronteggAuthIsLoading: false,
        })
      })

      const state = await FronteggNative.getAuthState()
      console.log(`FEUPNative: setting initial fronteggAuthState`, JSON.stringify(state, null, 2))
      setFronteggAuth({
        fronteggUserId: state.user?.id,
        fronteggTenantId: state.user?.tenantId,
        fronteggTenantIds: state.user?.tenantIds,
        fronteggAccessToken: state.accessToken ?? undefined,
        fronteggAuthIsLoading: state.isLoading,
      })
    }

    initializeFronteggAuthState()

    return () => {
      if (unsubscriber) unsubscriber.remove()
    }
  }, [setFronteggAuth])

  console.log('FEUPNative: rendering children')
  return <>{children}</>
}

export function useLogin() {
  return () => {
    console.log('FEUPNative: loggging in with native')
    FronteggNative.login()
  }
}

export function useLogout() {
  return () => {
    console.log('FEUPNative: logging out native')
    FronteggNative.logout()
  }
}
