import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const serviceTicketFileAttachmentSchemaLiteral = {
  title: 'Service Ticket File Attachment Schema',
  describe: 'Describes a service ticket file attachment',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    serviceTicketId: {
      type: 'string',
      ref: 'serviceTickets',
    },
    fileName: {
      type: 'string',
    },
    fileType: {
      type: 'string',
    },
    filePath: {
      type: 'string',
    },
    fileSize: {
      type: 'number',
    },
    uploadedByUserId: {
      type: 'string',
      ref: 'users',
    },
    uploadedDate: {
      type: 'string',
      format: 'date-time',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'serviceTicketId',
    'fileName',
    'fileType',
    'filePath',
    'fileSize',
    'uploadedDate',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(serviceTicketFileAttachmentSchemaLiteral)

// aggregate the document type from the schema
export type ServiceTicketFileAttachment = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const serviceTicketFileAttachmentSchema: RxJsonSchema<ServiceTicketFileAttachment> =
  serviceTicketFileAttachmentSchemaLiteral

export type ServiceTicketFileAttachmentDocument = RxDocument<ServiceTicketFileAttachment>

export type ServiceTicketFileAttachmentCollection = RxCollection<ServiceTicketFileAttachment>
