import { FavoriteTask } from '@/lib/schemas/FavoriteTaskSchema'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function FavoriteTaskInfoTab({ favoriteTask }: Readonly<{ favoriteTask: FavoriteTask }>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Field</TableCell>
          <TableCell align="right">Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">ID</TableCell>
          <TableCell>{favoriteTask.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell>{favoriteTask.name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
