import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const siteFileAttachmentSchemaLiteral = {
  title: 'Site File Attachment Schema',
  describe: 'Describes a site file attachment',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    siteId: {
      type: 'string',
    },
    fileName: {
      type: 'string',
    },
    fileType: {
      type: 'string',
    },
    filePath: {
      type: 'string',
    },
    fileSize: {
      type: 'number',
    },
    uploadedByUserId: {
      type: 'string',
    },
    uploadedDate: {
      type: 'string',
      format: 'date-time',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'siteId',
    'fileName',
    'fileType',
    'filePath',
    'fileSize',
    'uploadedDate',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(siteFileAttachmentSchemaLiteral)

// aggregate the document type from the schema
export type SiteFileAttachment = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const siteFileAttachmentSchema: RxJsonSchema<SiteFileAttachment> = siteFileAttachmentSchemaLiteral

export type SiteFileAttachmentDocument = RxDocument<SiteFileAttachment>

export type SiteFileAttachmentCollection = RxCollection<SiteFileAttachment>
