import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth as useFirebaseAuth, useFunctions } from './FirebaseProvider'
import { useFronteggAuthState, useLogout as useFronteggLogout } from './FronteggUserProvider'

type FirebaseAuthState = {
  firebaseUserId?: string
  firebaseAuthIsLoading: boolean
}

type FirebaseAuthContextState = {
  firebaseAuth: FirebaseAuthState
  setFirebaseAuth: (firebaseAuth: FirebaseAuthState) => void
}

export const FirebaseAuthContext = createContext<FirebaseAuthContextState>({
  firebaseAuth: {
    firebaseAuthIsLoading: true,
  },
  setFirebaseAuth: () => {
    /* do nothing */
  },
})

export default function FirebaseUserProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [firebaseAuth, setFirebaseAuth] = useState<FirebaseAuthState>({ firebaseAuthIsLoading: true })
  const {
    fronteggAuth: { fronteggAuthIsLoading, fronteggUserId, fronteggAccessToken },
  } = useFronteggAuthState()
  const functions = useFunctions()
  const auth = useFirebaseAuth()

  // initialize firebase user
  useEffect(() => {
    console.log(`FBUP: useEffect fronteggAuthIsLoading=${fronteggAuthIsLoading} fronteggUser=${fronteggUserId}`)
    // wait for a frontegg user
    if (fronteggAuthIsLoading) {
      console.log('FBUP: frontegg user is still loading, just wait longer')
      return
    }

    console.log('FBUP: frontegg user is loaded, subscribe to firebase auth state changes')

    // Listen for an authenticated user
    const unsubscriber = onAuthStateChanged(auth, async (firebaseUser) => {
      console.log(`FBUP: in onAuthStateChanged firebaseUserId=${firebaseUser?.uid} fronteggUserId=${fronteggUserId}`)
      if (firebaseUser) {
        console.log(`FBUP: logged in to firebase, fetching user`)

        // verify the logged in firebase user matches the frontegg user
        console.log(`FBUP: verifying firebase user ${firebaseUser.uid} matches frontegg user ${fronteggUserId}`)
        if (firebaseUser.uid !== fronteggUserId && navigator.onLine) {
          console.log(`FBUP: logged in firebase user does not match frontegg user, logging out`)
          await signOut(auth)
          return
        }

        console.log(`FBUP: logged in to firebase, setting firebase auth state to ${firebaseUser.uid}`)
        setFirebaseAuth({
          firebaseUserId: firebaseUser.uid,
          firebaseAuthIsLoading: false,
        })
      } else {
        console.log(`FBUP: not logged in to firebase`)

        // if there's not a frontegg user, then the user is logged out and we can stop loading now
        if (!fronteggUserId) {
          console.log(
            `FBUP: there is not a firebase user or frontegg user, so we are logged out of both.  stop loading.`,
          )
          setFirebaseAuth({
            firebaseAuthIsLoading: false,
          })
          return
        }

        console.log(
          `FBUP: not logged in to firebase but are logged in to frontegg, fetching firebase token from frontegg token`,
        )

        // call a server function to swap the frontegg token for a firebase token
        const getOrCreateUserFromFronteggToken = httpsCallable(functions, 'get_or_create_user_from_frontegg_token')
        const firebaseToken = (await (
          await getOrCreateUserFromFronteggToken({ token: fronteggAccessToken })
        ).data) as string
        console.log(`FBUP: have firebase token`, firebaseToken)

        // now login to firebase
        console.log(`FBUP: logging in to firebase with custom token`)
        await signInWithCustomToken(auth, firebaseToken)
      }
    })

    // Unsubscribe auth listener on unmount
    return (): void => unsubscriber()
  }, [auth, functions, fronteggAuthIsLoading, fronteggUserId, fronteggAccessToken])

  const value = useMemo(() => {
    return { firebaseAuth, setFirebaseAuth }
  }, [firebaseAuth, setFirebaseAuth])

  console.log('FBUP: rendering children')
  return <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>
}

export function useFirebaseAuthState() {
  return useContext(FirebaseAuthContext)
}

export function useLogout() {
  const auth = useFirebaseAuth()
  const fronteggLogout = useFronteggLogout()
  return async () => {
    console.log('FBUP: logging out')
    await signOut(auth)
    if (navigator.onLine) fronteggLogout()
  }
}
