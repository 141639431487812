import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const locationSchemaLiteral = {
  title: 'Location Schema',
  describe: 'Describes a location',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    siteId: {
      type: 'string',
      ref: 'sites',
    },
    buildingId: {
      type: 'string',
      ref: 'buildings',
    },
    floorId: {
      type: 'string',
      ref: 'floors',
    },
    projectId: {
      type: 'string',
      ref: 'projects',
    },
    name: {
      type: 'string',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
      ref: 'users',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    imageLeft: {
      type: 'number',
    },
    imageTop: {
      type: 'number',
    },
    primaryDeviceId: {
      type: 'string',
      ref: 'devices',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'siteId',
    'buildingId',
    'floorId',
    'imageLeft',
    'imageTop',
    'primaryDeviceId',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(locationSchemaLiteral)

// aggregate the document type from the schema
export type Location = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const locationSchema: RxJsonSchema<Location> = locationSchemaLiteral

export type LocationDocument = RxDocument<Location>

export type LocationCollection = RxCollection<Location>
