import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum OrgMemberRole {
  hq_administrator = 'hq_administrator',
  hq_coordinator = 'hq_coordinator',
  hq_staff_member = 'hq_staff_member',
  hq_technician = 'hq_technician',
  account_view_only = 'account_view_only',
  subcontractor = 'subcontractor',
  end_user_view_only = 'end_user_view_only',
  end_user_contributor = 'end_user_contributor',
  end_user_collaborator = 'end_user_collaborator',
  vendor_manager = 'vendor_manager',
  vendor_technician = 'vendor_technician',
  siteowl_support = 'siteowl_support',
}

export enum OrgMemberStatus {
  mail_sent = 'mail_sent',
  accepted = 'accepted',
}

const orgMemberSchemaLiteral = {
  title: 'Org Member Schema',
  describe: 'Describes an organization member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 100 },
    orgId: { type: 'string', ref: 'orgs' },
    userId: { type: 'string', ref: 'users' },
    role: {
      type: 'string',
      enum: Object.values(OrgMemberRole),
    },
    status: {
      type: 'string',
      enum: Object.values(OrgMemberStatus),
    },
    emailReport: { type: 'boolean' },
    isDefaultAccountAdmin: { type: 'boolean' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'userId', 'role', 'status', 'emailReport', 'isDefaultAccountAdmin', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(orgMemberSchemaLiteral)

// aggregate the document type from the schema
export type OrgMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const orgMemberSchema: RxJsonSchema<OrgMember> = orgMemberSchemaLiteral

export type OrgMemberDocument = RxDocument<OrgMember>

export type OrgMemberCollection = RxCollection<OrgMember>
