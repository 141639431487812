import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const favoriteSchemaLiteral = {
  title: 'Favorite Schema',
  describe: 'Describes a favorite',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    name: {
      type: 'string',
    },
    primaryDeviceId: {
      type: 'string',
      ref: 'devices',
    },
    primaryTaskId: {
      type: 'string',
      ref: 'tasks',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(favoriteSchemaLiteral)

// aggregate the document type from the schema
export type Favorite = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const favoriteSchema: RxJsonSchema<Favorite> = favoriteSchemaLiteral

export type FavoriteDocument = RxDocument<Favorite>

export type FavoriteCollection = RxCollection<Favorite>
