import React, { useState } from 'react'
import { Menu, MenuItem, ListItemIcon, ListItemText, Avatar, ListItemButton } from '@mui/material' // Import ListItemButton and Typography
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserState } from '../app/UserProvider'
import { faAnglesUpDown } from '@fortawesome/pro-solid-svg-icons'
import { useAuthActions } from '@frontegg/react'
import { Org } from '@/lib/schemas/OrgSchema'

const OrgPicker: React.FC = () => {
  const { switchTenant } = useAuthActions()
  const { orgs, currentOrg, setCurrentOrg } = useUserState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectOrg = (org: Org) => {
    setCurrentOrg(org)
    if (org.fronteggId) switchTenant({ tenantId: org.fronteggId })
    handleClose()
  }

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{ justifyContent: 'space-between', textTransform: 'none', px: '12px', fontSize: '16px' }}
      >
        <Avatar
          src={currentOrg?.logoImagePath ?? ''}
          alt={currentOrg?.name}
          sx={{ width: 30, height: 30, bgcolor: 'primary.main' }}
        >
          {!currentOrg?.logoImagePath && currentOrg?.name?.charAt(0)}
        </Avatar>
        {currentOrg?.name ?? 'Org Name'}
        <FontAwesomeIcon icon={faAnglesUpDown} size="xs" />
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              width: '224px',
              ml: '-8px',
              mt: '4px',
            },
          },
        }}
      >
        {orgs?.map((org) => (
          <MenuItem key={org.id} onClick={() => handleSelectOrg(org)} sx={{ px: '12px' }}>
            <ListItemIcon>
              <Avatar
                src={org.logoImagePath ?? ''}
                alt={org.name}
                sx={{ width: 30, height: 30, bgcolor: 'primary.main', mr: '14px' }}
              >
                {!org.logoImagePath && org.name.charAt(0)}
              </Avatar>
            </ListItemIcon>
            <ListItemText>{org.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default OrgPicker
