import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'
import { DataSource } from './DeviceVersionSchema'

const taskSchemaLiteral = {
  title: 'Task Schema',
  describe: 'Describes a task',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    siteId: {
      type: 'string',
      ref: 'sites',
    },
    buildingId: {
      type: 'string',
      ref: 'buildings',
    },
    floorId: {
      type: 'string',
      ref: 'floors',
    },
    locationId: {
      type: 'string',
      ref: 'locations',
    },
    uniqueId: {
      type: 'string',
    },
    equipmentId: {
      type: 'string',
      ref: 'equipment',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    installHours: {
      type: 'number',
    },
    installedDate: {
      type: 'string',
      format: 'date-time',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
      ref: 'users',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    installStatus: {
      type: 'string',
    },
    installNotes: {
      type: 'string',
    },
    installedByUserId: {
      type: 'string',
      ref: 'users',
    },
    dueDate: {
      type: 'string',
      format: 'date-time',
    },
    priority: {
      type: 'string',
    },
    isArchived: {
      type: 'boolean',
    },
    imagesCount: {
      type: 'number',
    },
    abbreviatedName: {
      type: 'string',
    },
    pinColor: {
      type: 'string',
      maxLength: 7,
    },
    isFlagged: {
      type: 'boolean',
    },
    flagNotes: {
      type: 'string',
    },
    flagCreatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    flagCreatedDate: {
      type: 'string',
      format: 'date-time',
    },
    createdSource: {
      type: 'string',
      enum: Object.values(DataSource),
    },
    updatedSource: {
      type: 'string',
      enum: Object.values(DataSource),
    },
    referenceDuplicateEquipmentId: {
      type: 'string',
      ref: 'equipment',
    },
    assignedToUserId: {
      type: 'string',
      ref: 'users',
    },
    projectId: {
      type: 'string',
      ref: 'projects',
    },
    orderWithinLocation: {
      type: 'number',
    },
    flagResolvedByUserId: {
      type: 'string',
      ref: 'users',
    },
    flagResolvedDate: {
      type: 'string',
      format: 'date-time',
    },
    budgetedCost: {
      type: 'number',
    },
    instructions: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'uniqueId',
    'equipmentId',
    'name',
    'installStatus',
    'priority',
    'isArchived',
    'imagesCount',
    'pinColor',
    'isFlagged',
    'createdSource',
    'updatedSource',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(taskSchemaLiteral)

// aggregate the document type from the schema
export type Task = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const taskSchema: RxJsonSchema<Task> = taskSchemaLiteral

export type TaskDocument = RxDocument<Task>

export type TaskCollection = RxCollection<Task>
