import { Capacitor } from '@capacitor/core'
import { FronteggProvider, FronteggProviderProps } from '@frontegg/react'
import { createContext, useContext, useMemo, useState } from 'react'
import FronteggUserProviderNative, {
  useLogin as useNativeLogin,
  useLogout as useNativeLogout,
} from './FronteggUserProviderNative'
import FronteggUserProviderWeb, { useLogin as useWebLogin, useLogout as useWebLogout } from './FronteggUserProviderWeb'

export type FronteggAuthState = {
  fronteggUserId?: string
  fronteggTenantId?: string
  fronteggTenantIds?: string[]
  fronteggAccessToken?: string
  fronteggAuthIsLoading: boolean
}

type FronteggAuthContextState = {
  fronteggAuth: FronteggAuthState
  setFronteggAuth: (auth: FronteggAuthState) => void
}

const contextOptions: FronteggProviderProps['contextOptions'] = {
  baseUrl: import.meta.env.VITE_FRONTEGG_BASE_URL ?? '',
  clientId: import.meta.env.VITE_FRONTEGG_CLIENT_ID,
}

const authOptions = {
  hostedLoginOptions: {
    loadUserOnFirstLoad: true,
  },
}

export const FronteggAuthContext = createContext<FronteggAuthContextState>({
  fronteggAuth: {
    fronteggAuthIsLoading: true,
  },
  setFronteggAuth: () => {
    /* do nothing */
  },
})

export default function FronteggUserProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [fronteggAuth, setFronteggAuth] = useState<FronteggAuthState>({ fronteggAuthIsLoading: true })
  console.log(`FEUP: rendering isNative=${Capacitor.isNativePlatform()}`)

  const value = useMemo(() => {
    return {
      fronteggAuth,
      setFronteggAuth,
    }
  }, [fronteggAuth, setFronteggAuth])

  return (
    <FronteggAuthContext.Provider value={value}>
      <FronteggProvider
        contextOptions={contextOptions}
        hostedLoginBox={false}
        authOptions={authOptions}
        customLoader={true}
      >
        {Capacitor.isNativePlatform() ? (
          <FronteggUserProviderNative>{children}</FronteggUserProviderNative>
        ) : (
          <FronteggUserProviderWeb>{children}</FronteggUserProviderWeb>
        )}
      </FronteggProvider>
    </FronteggAuthContext.Provider>
  )
}

export function useFronteggAuthState() {
  return useContext(FronteggAuthContext)
}

export function useLogin(): () => void {
  const nativeLogin = useNativeLogin()
  const webLogin = useWebLogin()
  return () => {
    if (Capacitor.isNativePlatform()) {
      nativeLogin()
    } else {
      webLogin()
    }
  }
}

export function useLogout(): () => void {
  const nativeLogout = useNativeLogout()
  const webLogout = useWebLogout()
  return () => {
    if (Capacitor.isNativePlatform()) {
      nativeLogout()
    } else {
      webLogout()
    }
  }
}
