import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDBSyncState } from './DBSyncProvider'
import { CircularProgress } from '@mui/material'

interface AccountSyncGuardProps {
  children: React.ReactNode
}

export function AccountSyncGuard({ children }: Readonly<AccountSyncGuardProps>) {
  const { accountId } = useParams<{ accountId: string }>()
  const { addAccountToSync, syncedAccountIds, firstSyncIsLoading } = useDBSyncState()

  useEffect(() => {
    if (!accountId || syncedAccountIds.includes(accountId)) return

    addAccountToSync(accountId).catch((error) => {
      console.error('Error syncing account:', error)
    })
  }, [accountId, addAccountToSync, syncedAccountIds])

  if (accountId && !syncedAccountIds.includes(accountId) && firstSyncIsLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <CircularProgress size="lg" />
      </div>
    )
  }

  return <>{children}</>
}
