import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useUser } from '../app/UserProvider'
import { useBreakpoints } from '@/lib/useBreakpoints'
import ThemePicker from './ThemePicker'

interface MyProfileModalProps {
  open: boolean
  onClose: () => void
}

const MyProfileModal: React.FC<MyProfileModalProps> = ({ open, onClose }) => {
  const currentUser = useUser()
  const { isSmallScreen } = useBreakpoints()

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, ...(isSmallScreen && modalFullScreenStyle) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            My Profile
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>{' '}
        <Typography sx={{ mt: 2 }}>Name: {currentUser.name}</Typography>
        <Typography sx={{ mt: 2 }}>Email: {currentUser.email}</Typography>
        <ThemePicker />
        {/* Add more profile details as needed */}
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const modalFullScreenStyle = {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  transform: 'none',
  display: 'flex',
  flexDirection: 'column',
  p: 2,
}

export default MyProfileModal
