import AccountProjectsPage from '../lib/ProjectsPage'
import { Project, ProjectStage } from '@/lib/schemas/ProjectSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Typography, Avatar, LinearProgress, Link } from '@mui/material'
import ProjectStatusChip from '../project/ProjectStatusChip'
import { User } from '@/lib/schemas/UserSchema'
import { RxDocument } from 'rxdb'
import { useBreakpoints } from '@/lib/useBreakpoints'

export default function AccountInstallationProjectsPage() {
  const { isTablet, isDesktop } = useBreakpoints()

  const columns: GridColDef<RxDocument<Project>>[] = [
    {
      field: 'name',
      headerName: 'Project',
      flex: 2,
      renderCell: (params) => {
        const project = params.row
        const user = (project as any).projectManagerUser as User
        const account = (project as any).account
        return (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <Box>
                <Link href={`/accounts/${account?.id}/projects/${project.id}`} variant="body1">
                  {params.value}
                </Link>
                <Typography variant="caption" color="textSecondary" sx={{ display: { xs: 'block', md: 'none' } }}>
                  4 Plans | 3 Flags | 34% Progress | 38% Operational | 100% Budgeted Hours
                </Typography>
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <ProjectStatusChip status={params.row.installationStatus} />
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, paddingTop: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={user?.profileImagePath} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {user?.name ?? 'Unknown'}
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      },
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 0,
        renderCell: () => <Typography sx={{ whiteSpace: 'nowrap' }}>4</Typography>, // Hardcoded count for now
      },
      {
        field: 'flags',
        headerName: 'Flags',
        flex: 0,
        renderCell: () => <Typography sx={{ whiteSpace: 'nowrap' }}>3</Typography>, // Hardcoded count for now
      },
      {
        field: 'progress',
        headerName: 'Progress',
        flex: 2,
        renderCell: () => (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={34} />
            <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
              34% of 100 Devices/Tasks Completed
            </Typography>
          </Box>
        ),
      },
      {
        field: 'operationalStatus',
        headerName: 'Operational Status',
        flex: 2,
        renderCell: () => (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={38} />
            <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
              38% of 79 Devices Operational
            </Typography>
          </Box>
        ),
      },
      {
        field: 'budgetedHours',
        headerName: 'Budgeted Hours',
        flex: 2,
        renderCell: () => (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={100} />
            <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
              100% of 54 Budgeted Hours Completed
            </Typography>
          </Box>
        ),
      },
    )
  }

  if (isDesktop) {
    columns.push(
      {
        field: 'projectManagerUserId',
        headerName: 'Project Manager',
        flex: 2,
        renderCell: (params) => {
          const project = params.row
          const user = (project as any).projectManagerUser as User
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={user?.profileImagePath ?? ''} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
              {user?.name ?? 'Unassigned'}
            </Box>
          )
        },
      },
      {
        field: 'installationStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <ProjectStatusChip status={params.value} />,
      },
    )
  }
  return (
    <AccountProjectsPage
      stage={ProjectStage.installation}
      title="Installation Projects"
      description="Installation Projects is used to define and manage the Installation of Projects."
      buttonLabel="New Installation"
      columns={columns}
    />
  )
}
