import { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useProjectMembersCollection } from '../app/DBProvider'

interface NewProjectMemberModalProps {
  open: boolean
  onClose: () => void
  onCreate: (member: any) => void
  accountId: string
  projectId: string
}

export default function NewProjectMemberModal({
  open,
  onClose,
  onCreate,
  accountId,
  projectId,
}: Readonly<NewProjectMemberModalProps>) {
  const membersCollection = useProjectMembersCollection()
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCreate = async () => {
    setIsSubmitting(true)
    try {
      const newProjectMember = await membersCollection.insert({
        id: crypto.randomUUID(),
        orgId: '', // Set appropriate orgId
        accountId,
        projectId,
        userId: '',
        _deleted: false,
      })
      onCreate(newProjectMember)
      onClose()
    } catch (error) {
      console.error('Failed to create member:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Invite Userr</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField label="User Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth required />
          <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={handleCreate} variant="contained" color="primary" disabled={isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
