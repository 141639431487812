import { Breadcrumbs as MuiBreadcrumbs, Link, Typography, IconButton, Box } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import {
  useAccountsCollection,
  useSitesCollection,
  useBuildingsCollection,
  useProjectsCollection,
  useFloorsCollection,
  useFavoritesCollection,
  useDevicesCollection,
  useTasksCollection,
  useLocationsCollection,
  useUsersCollection,
  useFavoriteDevicesCollection,
  useFavoriteTasksCollection,
} from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { Account } from '@/lib/schemas/AccountSchema'
import { Site } from '@/lib/schemas/SiteSchema'
import { Building } from '@/lib/schemas/BuildingSchema'
import { Project } from '@/lib/schemas/ProjectSchema'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { Device } from '@/lib/schemas/DeviceSchema'
import { Task } from '@/lib/schemas/TaskSchema'
import { Location } from '@/lib/schemas/LocationSchema'
import { User } from '@/lib/schemas/UserSchema'
import { FavoriteDevice } from '@/lib/schemas/FavoriteDeviceSchema'
import { FavoriteTask } from '@/lib/schemas/FavoriteTaskSchema'

export default function Breadcrumbs() {
  const {
    accountId,
    siteId,
    buildingId,
    projectId,
    floorId,
    favoriteId,
    deviceId,
    taskId,
    locationId,
    userId,
    favoriteDeviceId,
    favoriteTaskId,
  } = useParams<{
    accountId: string
    siteId: string
    buildingId: string
    projectId: string
    floorId: string
    favoriteId: string
    deviceId: string
    taskId: string
    locationId: string
    userId: string
    favoriteDeviceId: string
    favoriteTaskId: string
  }>()
  const location = useLocation()
  const tabId = useParams<{ tabId: string }>().tabId
  const accountsCollection = useAccountsCollection()
  const sitesCollection = useSitesCollection()
  const buildingsCollection = useBuildingsCollection()
  const projectsCollection = useProjectsCollection()
  const floorsCollection = useFloorsCollection()
  const favoritesCollection = useFavoritesCollection()
  const devicesCollection = useDevicesCollection()
  const tasksCollection = useTasksCollection()
  const locationsCollection = useLocationsCollection()
  const usersCollection = useUsersCollection()
  const favoriteDevicesCollection = useFavoriteDevicesCollection()
  const favoriteTasksCollection = useFavoriteTasksCollection()

  const [account, setAccount] = useState<RxDocument<Account> | null>(null)
  const [site, setSite] = useState<RxDocument<Site> | null>(null)
  const [building, setBuilding] = useState<RxDocument<Building> | null>(null)
  const [project, setProject] = useState<RxDocument<Project> | null>(null)
  const [floor, setFloor] = useState<RxDocument<Floor> | null>(null)
  const [favorite, setFavorite] = useState<RxDocument<Favorite> | null>(null)
  const [device, setDevice] = useState<RxDocument<Device> | null>(null)
  const [task, setTask] = useState<RxDocument<Task> | null>(null)
  const [deviceLocation, setDeviceLocation] = useState<RxDocument<Location> | null>(null)
  const [user, setUser] = useState<RxDocument<User> | null>(null)
  const [favoriteDevice, setFavoriteDevice] = useState<RxDocument<FavoriteDevice> | null>(null)
  const [favoriteTask, setFavoriteTask] = useState<RxDocument<FavoriteTask> | null>(null)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true)
      try {
        const promises = []
        if (accountId) {
          promises.push(accountsCollection.findOne(accountId).exec().then(setAccount))
        }
        if (siteId) {
          promises.push(sitesCollection.findOne(siteId).exec().then(setSite))
        }
        if (buildingId) {
          promises.push(buildingsCollection.findOne(buildingId).exec().then(setBuilding))
        }
        if (projectId) {
          promises.push(projectsCollection.findOne(projectId).exec().then(setProject))
        }
        if (floorId) {
          promises.push(floorsCollection.findOne(floorId).exec().then(setFloor))
        }
        if (favoriteId) {
          promises.push(favoritesCollection.findOne(favoriteId).exec().then(setFavorite))
        }
        if (deviceId) {
          promises.push(devicesCollection.findOne(deviceId).exec().then(setDevice))
        }
        if (taskId) {
          promises.push(tasksCollection.findOne(taskId).exec().then(setTask))
        }
        if (locationId) {
          promises.push(locationsCollection.findOne(locationId).exec().then(setDeviceLocation))
        }
        if (userId) {
          promises.push(usersCollection.findOne(userId).exec().then(setUser))
        }
        if (favoriteDeviceId) {
          promises.push(favoriteDevicesCollection.findOne(favoriteDeviceId).exec().then(setFavoriteDevice))
        }
        if (favoriteTaskId) {
          promises.push(favoriteTasksCollection.findOne(favoriteTaskId).exec().then(setFavoriteTask))
        }
        await Promise.all(promises)
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetching(false)
      }
    }
    fetchData()
  }, [
    accountId,
    siteId,
    buildingId,
    projectId,
    floorId,
    favoriteId,
    deviceId,
    taskId,
    locationId,
    userId,
    favoriteDeviceId,
    favoriteTaskId,
    accountsCollection,
    sitesCollection,
    buildingsCollection,
    projectsCollection,
    floorsCollection,
    favoritesCollection,
    devicesCollection,
    tasksCollection,
    locationsCollection,
    usersCollection,
    favoriteDevicesCollection,
    favoriteTasksCollection,
  ])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  if (isFetching)
    return (
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        sx={{ mb: 2, fontSize: '0.875rem', color: 'text.secondary' }}
      ></MuiBreadcrumbs>
    )

  const pathnames = location.pathname.split('/').filter((x) => x)
  const breadcrumbs = pathnames.slice(0, tabId ? -2 : -1).map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join('/')}`
    let text = ''
    if (value === accountId && account) {
      text = account.name
    } else if (value === siteId && site) {
      text = site.name
    } else if (value === buildingId && building) {
      text = building.name
    } else if (value === projectId && project) {
      text = project.name
    } else if (value === floorId && floor) {
      text = floor.name
    } else if (value === favoriteId && favorite) {
      text = favorite.name ?? favorite.id
    } else if (value === deviceId && device) {
      text = device.id
    } else if (value === taskId && task) {
      text = task.name
    } else if (value === locationId && deviceLocation) {
      text = deviceLocation.id
    } else if (value === userId && user) {
      text = user.name
    } else if (value === favoriteDeviceId && favoriteDevice) {
      text = favoriteDevice.name
    } else if (value === favoriteTaskId && favoriteTask) {
      text = favoriteTask.name
    } else {
      text = value
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    return (
      <Link key={to} href={to} color="inherit" underline="hover">
        <Typography variant="caption" color="textSecondary">
          {text}
        </Typography>
      </Link>
    )
  })

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <MuiBreadcrumbs aria-label="breadcrumb" sx={{ mb: 2, fontSize: '0.875rem', color: 'text.secondary' }}>
        {breadcrumbs}
      </MuiBreadcrumbs>
      <IconButton onClick={handleCopyLink} size="small" sx={{ mb: 2, ml: 1 }}>
        <FontAwesomeIcon icon={faLink} style={{ fontSize: '0.75rem' }} />
      </IconButton>
    </Box>
  )
}
