import DevicesPage from '../lib/DevicesPage'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Typography, Link } from '@mui/material'
import { RxDocument } from 'rxdb'
import { useBreakpoints } from '@/lib/useBreakpoints'

export default function AccountProjectDevicesPage() {
  const { isTablet } = useBreakpoints()

  const columns: GridColDef<RxDocument<DeviceVersion>>[] = [
    {
      field: 'name',
      headerName: 'Device Name',
      flex: 2,
      renderCell: (params) => {
        const deviceVersion = params.row
        return (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <Box>
                <Link href={`/accounts/${deviceVersion.accountId}/devices/${deviceVersion.deviceId}`} variant="body1">
                  {params.value}
                </Link>
                <Typography variant="caption" color="textSecondary" sx={{ display: { xs: 'block', md: 'none' } }}>
                  Status: {deviceVersion.installStatus.charAt(0).toUpperCase() + deviceVersion.installStatus.slice(1)} |
                  Priority: {deviceVersion.priority.charAt(0).toUpperCase() + deviceVersion.priority.slice(1)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, paddingTop: 1 }}>
              <Typography variant="body2" color="textSecondary">
                Site:{' '}
                <Link href={`/accounts/${deviceVersion.accountId}/sites/${deviceVersion.siteId}`} variant="body1">
                  {(deviceVersion as any).site?.name ?? deviceVersion.siteId}
                </Link>{' '}
                | Building:{' '}
                <Link
                  href={`/accounts/${deviceVersion.accountId}/sites/${deviceVersion.siteId}/buildings/${deviceVersion.buildingId}`}
                  variant="body1"
                >
                  {(deviceVersion as any).building?.name ?? deviceVersion.buildingId}
                </Link>{' '}
                | Floor:{' '}
                <Link
                  href={`/accounts/${deviceVersion.accountId}/projects/${deviceVersion.projectId}/floors/${deviceVersion.floorId}`}
                  variant="body1"
                >
                  {(deviceVersion as any).floor?.name ?? deviceVersion.floorId}
                </Link>
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'equipmentId',
      headerName: 'Device Type',
      flex: 1,
      renderCell: (params) => <Typography>{(params.row as any).equipment?.name ?? params.value}</Typography>,
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'priority',
        headerName: 'Priority',
        flex: 1,
        renderCell: (params) => <Typography>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</Typography>,
      },
      {
        field: 'installStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <Typography>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</Typography>,
      },
      {
        field: 'projectId',
        headerName: 'Project',
        flex: 1,
        renderCell: (params) => (
          <Link href={`/accounts/${params.row.accountId}/projects/${params.value}`} variant="body1">
            {(params.row as any).project?.name ?? params.value}
          </Link>
        ),
      },
      {
        field: 'siteId',
        headerName: 'Site',
        flex: 1,
        renderCell: (params) => (
          <Link href={`/accounts/${params.row.accountId}/sites/${params.value}`} variant="body1">
            {(params.row as any).site?.name ?? params.value}
          </Link>
        ),
      },
      {
        field: 'buildingId',
        headerName: 'Building',
        flex: 1,
        renderCell: (params) => (
          <Link
            href={`/accounts/${params.row.accountId}/sites/${params.row.siteId}/buildings/${params.value}`}
            variant="body1"
          >
            {(params.row as any).building?.name ?? params.value}
          </Link>
        ),
      },
      {
        field: 'floorId',
        headerName: 'Plan',
        flex: 1,
        renderCell: (params) => (
          <Link
            href={`/accounts/${params.row.accountId}/projects/${params.row.projectId}/floors/${params.value}`}
            variant="body1"
          >
            {(params.row as any).floor?.name ?? params.value}
          </Link>
        ),
      },
    )
  }

  return (
    <DevicesPage
      title="Project Devices"
      description="Project Devices shows all of the Devices in ongoing Design and Installation Projects."
      buttonLabel="New Device"
      columns={columns}
      hasProject={true}
    />
  )
}
