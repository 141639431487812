import React from 'react'
import { Autocomplete, TextField, InputAdornment } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useAccountsCollection, useUsersCollection } from '../app/DBProvider'
import { useRxQuery } from 'rxdb-hooks'

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '300px',
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    height: '39px',
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
}))

export default function Search() {
  const navigate = useNavigate()
  const accountsCollection = useAccountsCollection()
  const usersCollection = useUsersCollection()
  const [value, setValue] = React.useState<{ label: string; value: string } | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [recentAccounts, setRecentAccounts] = React.useState<{ label: string; value: string }[]>([])

  React.useEffect(() => {
    const fetchRecentAccountIds = async () => {
      const localDoc = await usersCollection.getLocal('recentAccountIds')
      if (!localDoc) return
      const recentAccountIds = localDoc.get('accountIds')
      if (recentAccountIds.length > 0) {
        const recentAccounts = await accountsCollection.find().where('id').in(recentAccountIds).exec()
        setRecentAccounts(recentAccounts.map((account) => ({ label: account.name, value: account.id })))
      }
    }
    fetchRecentAccountIds()
  }, [accountsCollection, usersCollection])

  const { result: accounts } = useRxQuery(
    inputValue
      ? accountsCollection.find().where('name').regex({ $regex: inputValue, $options: 'i' }).sort({ name: 'asc' })
      : accountsCollection.find().sort({ name: 'asc' }).limit(5),
  )

  let searchOptions = []
  if (inputValue) {
    searchOptions = accounts.map((account) => ({ label: account.name, value: account.id }))
  } else if (recentAccounts.length > 0) {
    searchOptions = recentAccounts.map((account) => ({
      group: 'Recently Used',
      label: account.label,
      value: account.value,
    }))
  } else {
    searchOptions = accounts.map((account) => ({ group: 'Recently Used', label: account.name, value: account.id }))
  }

  const handleAutocompleteChange = async (event: any, newValue: any) => {
    setValue(null)
    setInputValue('')
    const updatedAccounts = [
      { label: newValue.label, value: newValue.value },
      ...recentAccounts.filter((acc) => acc.value !== newValue.value),
    ].slice(0, 5)
    await usersCollection.upsertLocal('recentAccountIds', { accountIds: updatedAccounts.map((acc) => acc.value) })
    setRecentAccounts(updatedAccounts)
    navigate(`/accounts/${newValue.value}`)
  }

  return (
    <StyledAutocomplete
      filterOptions={(x) => x}
      options={searchOptions}
      noOptionsText="No accounts"
      value={value}
      inputValue={inputValue}
      onChange={handleAutocompleteChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      groupBy={(option) => (option as any).group || ''}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Search..."
            value={inputValue} // Bind the value prop to inputValue
            slotProps={{
              input: {
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )
      }}
    />
  )
}
