import { ProjectFloor } from '@/lib/schemas/ProjectFloorSchema'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function ProjectFloorInfoTab({ projectFloor }: Readonly<{ projectFloor: ProjectFloor }>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Field</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">ID</TableCell>
          <TableCell>{projectFloor.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Project ID</TableCell>
          <TableCell>{projectFloor.projectId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Floor ID</TableCell>
          <TableCell>{projectFloor.floorId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Plan Image Name</TableCell>
          <TableCell>{projectFloor.planImageName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Plan Image Path</TableCell>
          <TableCell>{projectFloor.planImagePath}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
