import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { useState } from 'react'

interface NewServiceTicketModalProps {
  open: boolean
  onClose: () => void
  onCreate: (serviceTicket: { name: string; accountId: string }) => void
  accountId: string
}

export default function NewServiceTicketModal({
  open,
  onClose,
  onCreate,
  accountId,
}: Readonly<NewServiceTicketModalProps>) {
  const [name, setName] = useState('')

  const handleCreate = () => {
    onCreate({ name, accountId })
    setName('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New ServiceTicket</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="ServiceTicket Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreate} disabled={!name}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
