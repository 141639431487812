import NotFoundPage from '@/components/NotFoundPage'
import {
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useFloorsCollection, useLocationsCollection } from '../app/DBProvider'
import Breadcrumbs from '../layout/Breadcrumbs'
import { MapContainer, ImageOverlay, Marker, useMap, ZoomControl, Tooltip } from 'react-leaflet'
import { LatLngBoundsExpression, CRS, DivIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react'
import { Device } from '@/lib/schemas/DeviceSchema'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'
import { EquipmentType } from '@/lib/schemas/EquipmentTypeSchema'

function FitBounds({ bounds }: { bounds: LatLngBoundsExpression }) {
  const map = useMap()

  useEffect(() => {
    map.fitBounds(bounds)
  }, [map, bounds])

  return null
}

// Create a custom icon using the font icon
const createCustomIcon = (iconName: string) =>
  new DivIcon({
    className: 'custom-div-icon',
    html: `
    <div style="position: relative; color: #00B2F8;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 40px; height: 40px;">
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2.5"/>
            <feOffset dx="1" dy="2.5" result="offsetblur"/>
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.3"/>
            </feComponentTransfer>
            <feMerge>
              <feMergeNode/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <path fill="currentColor" d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z" filter="url(#shadow)"/>
      </svg>
      <div class="equipment-icon ${iconName}" style="position: absolute; top: 37%; left: 50%; transform: translate(-50%, -50%); font-size: 20px; color: white; font-weight: bold;"></div>
    </div>
  `,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  })

export default function FloorPage() {
  const floorsCollection = useFloorsCollection()
  const locationsCollection = useLocationsCollection()
  const { floorId = 'info' } = useParams()
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null)
  const [isPopulated, setIsPopulated] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)
  const [editMode, setEditMode] = useState(false)
  const [editedDevice, setEditedDevice] = useState<Device | null>(null)

  const { result: floors, isFetching: isFloorsFetching } = useRxQuery(floorsCollection.findOne(floorId))

  const { result: locations, isFetching: isLocationsFetching } = useRxQuery(
    locationsCollection.find({ selector: { floorId: floorId } }),
  )

  useEffect(() => {
    if (selectedDevice) {
      setEditedDevice(selectedDevice)
    }
  }, [selectedDevice])

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
    setEditedDevice(selectedDevice)
  }

  const handleSave = async () => {
    if (!editedDevice) return
    // TODO: Implement save functionality
    setEditMode(false)
  }

  useEffect(() => {
    if (isLocationsFetching || !locations) return
    const populateLocations = async () => {
      for (const location of locations) {
        if (!location.primaryDeviceId) continue
        const primaryDevice = await location.populate('primaryDeviceId')
        ;(location as any).primaryDevice = primaryDevice
        if (!primaryDevice) continue
        const deviceVersion = await primaryDevice.populate('currentVersionId')
        ;(location as any).primaryDevice.currentVersion = deviceVersion
        const equipment = await deviceVersion.populate('equipmentId')
        ;(location as any).primaryDevice.currentVersion.equipment = equipment
      }
      setIsPopulated(true)
    }
    populateLocations()
  }, [isLocationsFetching, locations])

  useEffect(() => {
    if (!floors?.[0]?.planImagePath) return

    const img = new Image()
    img.src = `${import.meta.env.VITE_IMGIX_BASE_URL}/${floors[0].planImagePath}`
    img.onload = () => {
      setImageDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  }, [floors])

  if (isFloorsFetching || isLocationsFetching || !imageDimensions || !isPopulated) return <></>

  const floor = floors?.[0]
  if (!floor) return <NotFoundPage />

  const imageBounds: LatLngBoundsExpression = [
    [0, 0], // Southwest coordinates
    [imageDimensions.height, imageDimensions.width], // Northeast coordinates
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">{floor.name}</Typography>
        {!editMode ? (
          <Button variant="outlined" size="small" onClick={handleEdit}>
            Edit
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={handleSave}>
              Save
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <MapContainer
          className="map-container"
          center={[imageDimensions.height / 2, imageDimensions.width / 2]}
          scrollWheelZoom={false}
          crs={CRS.Simple}
          minZoom={-5}
          maxZoom={3}
          style={{ height: '100%', width: '100%' }}
          zoomControl={false}
        >
          <FitBounds bounds={imageBounds} />
          <ZoomControl position="topright" />
          <ImageOverlay url={`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`} bounds={imageBounds} />
          {locations?.map((location) => {
            const x = (location.imageLeft / 100) * imageDimensions.width
            const y = imageDimensions.height - (location.imageTop / 100) * imageDimensions.height

            const primaryDevice = (location as any).primaryDevice as Device
            if (!primaryDevice || primaryDevice.status !== 'published') return null

            const deviceVersion = (primaryDevice as any).currentVersion as DeviceVersion
            if (!deviceVersion) return null

            const equipment = (deviceVersion as any).equipment as EquipmentType
            if (!equipment) return null

            return (
              <Marker
                key={location.id}
                position={[y, x]}
                icon={createCustomIcon(equipment.iconName)}
                eventHandlers={{
                  click: () => setSelectedDevice(primaryDevice),
                }}
              >
                <Tooltip permanent direction="bottom" opacity={1}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '10px', marginBottom: '2px' }}>
                      {deviceVersion.name} {primaryDevice.id}
                    </div>
                    {deviceVersion.abbreviatedName && (
                      <>
                        <hr style={{ margin: '2px 0', border: 'none', borderTop: '1px solid #ccc' }} />
                        <div style={{ fontSize: '10px', textTransform: 'capitalize' }}>
                          {deviceVersion.abbreviatedName}
                        </div>
                      </>
                    )}
                    {deviceVersion.partNumber && (
                      <>
                        <hr style={{ margin: '2px 0', border: 'none', borderTop: '1px solid #ccc' }} />
                        <div style={{ fontSize: '10px', textTransform: 'capitalize' }}>{deviceVersion.partNumber}</div>
                      </>
                    )}
                  </div>
                </Tooltip>
              </Marker>
            )
          })}
        </MapContainer>
        {selectedDevice && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '330px',
              height: '100%',
              backgroundColor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <div
                  className={`equipment-icon ${(selectedDevice as any).currentVersion?.equipment?.iconName}`}
                  style={{
                    fontSize: '24px',
                    color: '#00B2F8',
                    fontWeight: 'bold',
                  }}
                />
                <Typography variant="subtitle1" noWrap>
                  {(selectedDevice as any).currentVersion?.name}
                </Typography>
              </Box>
              <IconButton size="small" onClick={() => setSelectedDevice(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <Accordion defaultExpanded elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Identification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Device Name
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.name || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersionId,
                                name: e.target.value,
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.name || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Abbreviated Name
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.abbreviatedName || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersionId,
                                abbreviatedName: e.target.value,
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.abbreviatedName || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        System Type
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.equipment?.systemType || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersionId,
                                equipment: {
                                  ...(editedDevice as any).currentVersion?.equipment,
                                  systemType: e.target.value,
                                },
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.equipment?.systemType || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Device Type
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.equipment?.deviceType || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersion,
                                equipment: {
                                  ...(editedDevice as any).currentVersion?.equipment,
                                  deviceType: e.target.value,
                                },
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.equipment?.deviceType || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Manufacturer
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.equipment?.manufacturer || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersion,
                                equipment: {
                                  ...(editedDevice as any).currentVersion?.equipment,
                                  manufacturer: e.target.value,
                                },
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.equipment?.manufacturer || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Part Number
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.currentVersion?.partNumber || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersionId,
                                partNumber: e.target.value,
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.partNumber || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Description
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          multiline
                          rows={2}
                          value={(editedDevice as any)?.currentVersion?.description || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersionId,
                                description: e.target.value,
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).currentVersion?.description || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Project Name
                      </Typography>
                      {editMode ? (
                        <TextField
                          fullWidth
                          size="small"
                          value={(editedDevice as any)?.projectId || ''}
                          onChange={(e) => {
                            if (!editedDevice) return
                            setEditedDevice({
                              ...editedDevice,
                              currentVersionId: {
                                ...(editedDevice as any).currentVersion,
                                projectId: e.target.value,
                              },
                            })
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          {(selectedDevice as any).projectId || (
                            <Typography component="span" variant="caption" color="text.disabled">
                              empty
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Installation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add installation content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Images</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add images content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Technical</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add technical content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Coverage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add coverage content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Connections</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add connections content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Warranty</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add warranty content */}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                sx={{
                  '&.Mui-expanded': {
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  },
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">Service Tickets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{/* Add service tickets content */}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
