import ReactDOM from 'react-dom/client'
import App from './components/App'
import * as Sentry from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'
import './index.css'
import './assets/icons.css'
import { createRoutesFromElements, Route, RouterProvider } from 'react-router'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import OrgAccountsPage from './components/org/OrgAccountsPage'
import BuildingPage from './components/building/BuildingPage'
import DevicePage from './components/device/DevicePage'
import FavoritePage from './components/favorite/FavoritePage'
import FavoriteDevicePage from './components/favoriteDevice/FavoriteDevicePage'
import FavoriteTaskPage from './components/favoriteTask/FavoriteTaskPage'
import FloorPage from './components/floor/FloorPage'
import LocationPage from './components/location/LocationPage'
import NotFoundPage from './components/NotFoundPage'
import ProjectPage from './components/project/ProjectPage'
import ProjectFloorPage from './components/projectFloor/ProjectFloorPage'
import ServiceTicketPage from './components/serviceTicket/ServiceTicketPage'
import SitePage from './components/site/SitePage'
import TaskPage from './components/task/TaskPage'
import AccountDesignProjectsPage from '@/components/account/AccountDesignProjectsPage'
import AccountInstallationProjectsPage from '@/components/account/AccountInstallationProjectsPage'
import AccountSitesPage from './components/account/AccountSitesPage'
import AccountServiceTicketsPage from './components/account/AccountServiceTicketsPage'
import OrgInstallationActivityPage from './components/org/OrgInstallationActvityPage'
import OrgDesignProjectsPage from './components/org/OrgDesignProjectsPage'
import OrgInstallationProjectsPage from './components/org/OrgInstallationProjectsPage'
import AccountProjectDevicesPage from './components/account/AccountProjectDevicesPage'
import AccountLivePlanningPage from './components/account/AccountLivePlanningPage'
import AccountLiveDevicesPage from './components/account/AccountLiveDevicesPage'
import AccountPage from './components/account/AccountPage'
import AccountDashboardPage from './components/account/AccountDashboardPage'
import { LicenseInfo } from '@mui/x-license'
import OrgServiceTicketsPage from './components/org/OrgServiceTicketsPage'
import { AccountSyncGuard } from './components/app/AccountSyncGuard'

// Set the license key for the MUI X components
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY || '')

if (import.meta.env.VITE_NODE_ENV !== 'development') {
  // initialize Sentry exception tracking
  Sentry.init(
    {
      dsn: 'https://c70257385ed207dd7f87d586efaeb3e3@o4507172132421632.ingest.us.sentry.io/4507172134977536',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
      ],
      // use the git commit SHA as the release version
      release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
      // set the environment
      environment: import.meta.env.VITE_NODE_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    }, // Forward the init method from @sentry/angular
    SentryReact.init,
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<App />}>
      <Route path="" element={<Navigate to="accounts" replace />} />
      <Route path="design-projects" element={<OrgDesignProjectsPage />} />
      <Route path="installation-projects" element={<OrgInstallationProjectsPage />} />
      <Route path="installation-activity" element={<OrgInstallationActivityPage />} />
      <Route path="service-tickets" element={<OrgServiceTicketsPage />} />
      <Route path="accounts" element={<OrgAccountsPage />} />
      <Route path="other-accounts" element={<OrgAccountsPage />} />

      {/* Account-specific routes wrapped in AccountSyncGuard */}
      <Route
        path="accounts/:accountId/*"
        element={
          <AccountSyncGuard>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AccountDashboardPage />} />
            <Route path="design-projects/:tabId?" element={<AccountDesignProjectsPage />} />
            <Route path="installation-projects/:tabId?" element={<AccountInstallationProjectsPage />} />
            <Route path="project-devices" element={<AccountProjectDevicesPage />} />
            <Route path="live-planning" element={<AccountLivePlanningPage />} />
            <Route path="projects/:projectId/:tabId?" element={<ProjectPage />} />
            <Route path="projects/:projectId/floors/:floorId/:tabId?" element={<ProjectFloorPage />} />
            <Route path="sites" element={<AccountSitesPage />} />
            <Route path="archived-sites" element={<AccountSitesPage />} />
            <Route path="sites/:siteId/:tabId?" element={<SitePage />} />
            <Route path="sites/:siteId/buildings/:buildingId/:tabId?" element={<BuildingPage />} />
            <Route path="sites/:siteId/buildings/:buildingId/floors/:floorId/:tabId?" element={<FloorPage />} />
            <Route
              path="sites/:siteId/buildings/:buildingId/floors/:floorId/locations/:locationId/:tabId?"
              element={<LocationPage />}
            />
            <Route
              path="sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId/:tabId?"
              element={<DevicePage />}
            />
            <Route
              path="sites/:siteId/buildings/:buildingId/floors/:floorId/tasks/:taskId/:tabId?"
              element={<TaskPage />}
            />
            <Route path="favorites/:favoriteId/:tabId?" element={<FavoritePage />} />
            <Route path="favorites/:favoriteId/devices/:favoriteDeviceId/:tabId?" element={<FavoriteDevicePage />} />
            <Route path="favorites/:favoriteId/tasks/:favoriteTaskId/:tabId?" element={<FavoriteTaskPage />} />
            <Route path="service-tickets" element={<AccountServiceTicketsPage />} />
            <Route path="service-tickets/:serviceTicketId/:tabId?" element={<ServiceTicketPage />} />
            <Route path="live-devices" element={<AccountLiveDevicesPage />} />
            <Route path="settings/:tabId?" element={<AccountPage />} />
          </AccountSyncGuard>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RouterProvider
    router={router}
    future={{
      v7_startTransition: true,
    }}
  />,
)
