import { Device } from '@/lib/schemas/DeviceSchema'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function DeviceInfoTab({ device }: Readonly<{ device: Device }>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Field</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">ID</TableCell>
          <TableCell>{device.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Status</TableCell>
          <TableCell>{device.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Current Version ID</TableCell>
          <TableCell>{device.currentVersionId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Order Within Location</TableCell>
          <TableCell>{device.orderWithinLocation}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Floor ID</TableCell>
          <TableCell>{device.floorId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Building ID</TableCell>
          <TableCell>{device.buildingId}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
