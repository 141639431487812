import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useDeviceVersionsCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { useCurrentOrg } from '../app/UserProvider'
import { useBreakpoints } from '@/lib/useBreakpoints'
import CustomPagination from '../lib/CustomPagination'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'

export function NoDeviceRowsOverlay() {
  return <NoRowsOverlay message="No devices" />
}

export default function DevicesTable({
  hasProject = false,
  projectId,
  installStatus,
  columns,
}: Readonly<{
  hasProject?: boolean
  projectId?: string
  installStatus?: string | string[]
  columns: GridColDef<RxDocument<DeviceVersion>>[]
}>) {
  const { accountId } = useParams<{ accountId: string }>()
  const deviceVersionsCollection = useDeviceVersionsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const { isPhone } = useBreakpoints()
  const navigate = useNavigate()
  const currentOrg = useCurrentOrg()

  const rxQuery = useMemo(() => {
    let query = deviceVersionsCollection.find().where('orgId').equals(currentOrg.id)

    if (accountId) {
      query = query.where('accountId').equals(accountId)
    }

    if (projectId) {
      query = query.where('projectId').equals(projectId)
    } else {
      query = query.where('projectId').exists(hasProject)
    }

    if (installStatus) {
      if (Array.isArray(installStatus)) {
        query = query.where('installStatus').in(installStatus)
      } else {
        query = query.where('installStatus').equals(installStatus)
      }
    }

    return query
  }, [deviceVersionsCollection, currentOrg.id, accountId, hasProject, projectId, installStatus])

  const { result: deviceVersions, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !deviceVersions) return
    const populateDevices = async () => {
      for (const deviceVersion of deviceVersions) {
        const site = await deviceVersion.populate('siteId')
        ;(deviceVersion as any).site = site
        const building = await deviceVersion.populate('buildingId')
        ;(deviceVersion as any).building = building
        const floor = await deviceVersion.populate('floorId')
        ;(deviceVersion as any).floor = floor
        const project = await deviceVersion.populate('projectId')
        ;(deviceVersion as any).project = project
        const equipment = await deviceVersion.populate('equipmentId')
        ;(deviceVersion as any).equipment = equipment
      }
      setIsPopulated(true)
    }
    populateDevices()
  }, [isFetching, deviceVersions])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.accountId}/devices/${params.row.deviceId}`)
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoDeviceRowsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  if (isFetching || !deviceVersions || !isPopulated) return <></>

  return (
    <DataGridPremium
      columns={columns}
      rows={deviceVersions} // Use devices directly
      getRowId={(row) => row.id} // Add getRowId function
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' as GridSortDirection }], // Set initial sort
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick} // Add onRowClick handler only for phone screens
      getRowHeight={() => 'auto'} // Set row height to auto
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        // this removes the drag handles
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        // this removes the cell focus
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center', // Center values vertically
          paddingTop: '16px', // Add padding to the top of each row
          paddingBottom: '16px', // Add padding to the bottom of each row
        },
        [`& .${gridClasses.row}`]: {
          cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none', // Remove top border
          },
          marginLeft: -3, // Add negative left padding for small screens
          marginRight: -3, // Add negative right padding for small screens
          [`& .${gridClasses.cell}`]: {
            paddingX: 3, // Add positive right padding for small screens
            paddingY: 2, // Add positive top and bottom padding for small screens
          },
        }),
      }}
    />
  )
}

function CustomToolbar() {
  const { isPhone } = useBreakpoints()

  return (
    <GridToolbarContainer
      sx={{
        ...(isPhone && {
          paddingLeft: 3, // Add positive left padding for small screens
          paddingRight: 3, // Add positive right padding for small screens
        }),
      }}
    >
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}
