import { Box, Typography } from '@mui/material'

type HeaderProps = {
  title: string
  description?: string
}

export default function PageHeader({ title, description }: Readonly<HeaderProps>) {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">{title}</Typography>
      {description && <Typography variant="body2">{description}</Typography>}
    </Box>
  )
}
