import NotFoundPage from '@/components/NotFoundPage'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router'
import { Link } from '@mui/material'
import { useRxQuery } from 'rxdb-hooks'
import { useAccountsCollection } from '../app/DBProvider'
import AccountInfoTab from './AccountInfoTab'
import AccountMembersTab from './AccountMembersTab'

const tabValues = ['info', 'members']

export default function AccountPage() {
  const { accountId, tabId = 'info' } = useParams<{ accountId: string; tabId: string }>()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const accountsCollection = useAccountsCollection()
  const { result: accounts, isFetching } = useRxQuery(accountsCollection.findOne(accountId))

  if (isFetching) return <></>

  const account = accounts?.[0]
  if (!account) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{account.name}</Typography>
        </Box>
      </Box>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Information" component={Link} href={`/accounts/${accountId}/settings`} />
        <Tab label="Members" component={Link} href={`/accounts/${accountId}/settings/members`} />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tabIndex === 0 && <AccountInfoTab />}
        {tabIndex === 1 && <AccountMembersTab />}
      </Box>
    </>
  )
}
