import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum NotificationPreference {
  off = 'off',
  in_app = 'in_app',
  indashapp = 'in-app',
  push = 'push',
}

export enum DeliveryMethodPreference {
  email = 'email',
  sms = 'sms',
}

export enum ListOrGridPreference {
  list = 'list',
  grid = 'grid',
}

export enum DashboardTabPreference {
  design = 'design',
  support = 'support',
  install = 'install',
}

const userSchemaLiteral = {
  title: 'User Schema',
  describe: 'Describes a user',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    name: { type: 'string' },
    email: { type: 'string' },
    lastActiveDate: { type: 'string', format: 'date-time' },
    isActive: { type: 'boolean' },
    employer: { type: 'string' },
    cellNumber: { type: 'string' },
    workNumber: { type: 'string' },
    profileCompleted: { type: 'boolean' },
    profileImagePath: { type: 'string' },
    createdDate: { type: 'string', format: 'date-time' },
    createdByUserId: { type: 'string', ref: 'users' },
    customUserId: { type: 'string', ref: 'users' },
    fronteggUserId: { type: 'string' },
    touAcceptedDate: { type: 'string', format: 'date-time' },
    _deleted: { type: 'boolean' },
    uiPreferences: {
      type: 'object',
      properties: {
        showDeviceName: { type: 'boolean' },
        showFullSizeDeviceIcons: { type: 'boolean' },
        showFieldOfView: { type: 'boolean' },
        showPartNumber: { type: 'boolean' },
        triggeredByMonitoring: { type: 'boolean' },
        hideClosedTickets: { type: 'boolean' },
        pageSize: { type: 'number' },
        dashboardTab: { type: 'string', enum: Object.values(DashboardTabPreference) },
        listOrGridTab: { type: 'string', enum: Object.values(ListOrGridPreference) },
        showDeviceSize: { type: 'number' },
        showPlanMarkup: { type: 'boolean' },
        showArchivedDevices: { type: 'boolean' },
        planLibraryListOrGridTab: { type: 'string', enum: Object.values(ListOrGridPreference) },
        showAbbreviatedName: { type: 'boolean' },
        showConnectionLine: { type: 'boolean' },
        serviceTicketField: { type: 'string' },
        deliveryMethod: { type: 'string', enum: Object.values(DeliveryMethodPreference) },
      },
      required: [
        'showDeviceName',
        'showFullSizeDeviceIcons',
        'showFieldOfView',
        'showPartNumber',
        'triggeredByMonitoring',
        'hideClosedTickets',
        'showDeviceSize',
        'showPlanMarkup',
        'showArchivedDevices',
        'showAbbreviatedName',
        'showConnectionLine',
        'deliveryMethod',
      ],
    },
    notificationPreferences: {
      type: 'object',
      properties: {
        ticketCreated: { type: 'string', enum: Object.values(NotificationPreference) },
        ticketAssigned: { type: 'string', enum: Object.values(NotificationPreference) },
        ticketClosed: { type: 'string', enum: Object.values(NotificationPreference) },
        ticketCommented: { type: 'string', enum: Object.values(NotificationPreference) },
        ticketUpdated: { type: 'string', enum: Object.values(NotificationPreference) },
      },
      required: ['ticketCreated', 'ticketAssigned', 'ticketClosed', 'ticketCommented', 'ticketUpdated'],
    },
    messagingRegistrationTokens: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          token: { type: 'string' },
          timestamp: { type: 'string', format: 'date-time' },
        },
        required: ['token', 'timestamp'],
        additionalProperties: false,
      },
    },
  },
  required: [
    'id',
    'name',
    'email',
    'isActive',
    'profileCompleted',
    'fronteggUserId',
    'uiPreferences',
    'notificationPreferences',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(userSchemaLiteral)

// aggregate the document type from the schema
export type User = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>
// extract the type of the messagingRegistrationTokens array
export type MessagingRegistrationToken = NonNullable<User['messagingRegistrationTokens']>[0]

// create the typed RxJsonSchema from the literal typed object.
export const userSchema: RxJsonSchema<User> = userSchemaLiteral

export type UserDocument = RxDocument<User>

export type UserCollection = RxCollection<User>
