import NotFoundPage from '@/components/NotFoundPage'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useServiceTicketsCollection } from '../app/DBProvider'
import ServiceTicketFileAttachmentsTab from './ServiceTicketFilesTab'
import ServiceTicketInfoTab from './ServiceTicketInfoTab'
import Breadcrumbs from '../layout/Breadcrumbs'
import ServiceTicketDevicesTab from './ServiceTicketDevicesTab'
import ServiceTicketQuoteTab from './ServiceTicketQuoteTab'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ServiceTicketEditModal from './ServiceTicketEditModal'

const tabValues = ['info', 'devices', 'quote', 'files']

export default function ServiceTicketPage() {
  const { accountId, serviceTicketId } = useParams<{ accountId: string; serviceTicketId: string }>()
  const tabId = useParams<{ tabId: string }>().tabId
  const serviceTicketsCollection = useServiceTicketsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  const { result: serviceTickets, isFetching } = useRxQuery(serviceTicketsCollection.findOne(serviceTicketId))
  const serviceTicket = !isFetching && serviceTickets?.length > 0 ? serviceTickets[0] : undefined

  useEffect(() => {
    if (isFetching || !serviceTicket) return
    const populateProject = async () => {
      setIsPopulated(true)
    }
    populateProject()
  }, [isFetching, serviceTicket])

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true)
    handleMenuClose()
  }

  function handleCloseModal(): void {
    setIsEditModalOpen(false)
  }

  if (isFetching || !isPopulated) return <></>
  if (!serviceTicket) return <NotFoundPage />

  // Determine the tab index based on the current route
  const tabIndex = tabValues.indexOf(tabId ?? 'info')

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
            {serviceTicket.subject}
          </Typography>
        </Box>
        {isPhone ? (
          <>
            <IconButton onClick={handleMenuClick} sx={{ alignSelf: 'flex-start' }}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleEditClick}>Edit Project</MenuItem>
            </Menu>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={handleEditClick} sx={{ alignSelf: 'flex-start' }}>
            Edit
          </Button>
        )}
      </Box>
      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" component={Link} href={`/accounts/${accountId}/service-tickets/${serviceTicketId}`} />
          <Tab
            label="Devices"
            component={Link}
            href={`/accounts/${accountId}/service-tickets/${serviceTicketId}/devices`}
          />
          <Tab
            label="Quote"
            component={Link}
            href={`/accounts/${accountId}/service-tickets/${serviceTicketId}/quote`}
          />
          <Tab
            label="Files"
            component={Link}
            href={`/accounts/${accountId}/service-tickets/${serviceTicketId}/files`}
          />
        </Tabs>
      </Box>
      <Divider sx={{ width: '100%', mb: 2 }} />
      {tabIndex === 0 && <ServiceTicketInfoTab serviceTicket={serviceTicket} />}
      {tabIndex === 1 && <ServiceTicketDevicesTab serviceTicket={serviceTicket} />}
      {tabIndex === 2 && <ServiceTicketQuoteTab serviceTicket={serviceTicket} />}
      {tabIndex === 3 && <ServiceTicketFileAttachmentsTab serviceTicket={serviceTicket} />}
      <ServiceTicketEditModal open={isEditModalOpen} onClose={handleCloseModal} serviceTicket={serviceTicket} />
    </>
  )
}
