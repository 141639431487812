import 'external-svg-loader'
import UserProvider from './app/UserProvider'
import Layout from './layout/Layout'
import FirebaseProvider from './app/FirebaseProvider'
import FronteggUserProvider from './app/FronteggUserProvider'
import FirebaseUserProvider from './app/FirebaseUserProvider'
import AuthGuard from './app/AuthGuard'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeModeProvider from './layout/ThemeModeProvider'
import AppNotificationListener from './app/AppNotificationListener'
import AppUrlListener from './app/AppUrlListener'
import { StatusBar } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import DBProvider from './app/DBProvider'
import { CookiesProvider } from 'react-cookie'
import DBSyncProvider from './app/DBSyncProvider'

if (Capacitor.isNativePlatform()) {
  StatusBar.hide()
}

export default function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ThemeModeProvider>
        <CssBaseline />
        <FirebaseProvider>
          <FronteggUserProvider>
            <FirebaseUserProvider>
              <DBProvider>
                <DBSyncProvider>
                  <UserProvider>
                    <AuthGuard>
                      <Layout />
                      <AppUrlListener />
                      <AppNotificationListener />
                    </AuthGuard>
                  </UserProvider>
                </DBSyncProvider>
              </DBProvider>
            </FirebaseUserProvider>
          </FronteggUserProvider>
        </FirebaseProvider>
      </ThemeModeProvider>
    </CookiesProvider>
  )
}
