import { ReactElement } from 'react'
import { DeepReadonlyObject } from 'rxdb'
import { User } from '@/lib/schemas/UserSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import _ from 'lodash'
import AppDataGrid from '@/components/lib/AppDataGrid'

interface ServiceTicketWatchersTableProps {
  users: DeepReadonlyObject<User>[]
}

export default function ServiceTicketWatchersTable({ users }: Readonly<ServiceTicketWatchersTableProps>): ReactElement {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'deliveryMethod',
      headerName: 'Delivery Method',
      flex: 1,
    },
  ]

  const rows = _.map(users, (user: DeepReadonlyObject<User>) => ({
    id: user.id,
    name: user.name,
    deliveryMethod: _.get(user, 'uiPreferences.deliveryMethod'),
  }))

  return <AppDataGrid rows={rows} columns={columns} emptyMessage="No watchers" />
}
