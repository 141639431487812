import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum UnitOfMeasurement {
  meters = 'meters',
  feet = 'feet',
}

const accountSchemaLiteral = {
  title: 'Account Schema',
  describe: 'Describes a account',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    phoneNumber: {
      type: 'string',
    },
    isActive: {
      type: 'boolean',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
      ref: 'users',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    settings: {
      type: 'object',
      properties: {
        isLocalSaveEnabled: {
          type: 'boolean',
        },
        isCablingEnabled: {
          type: 'boolean',
        },
        defaultAssignedToUserId: {
          type: 'string',
          ref: 'users',
        },
        isUnlimitedPlansInReportsEnabled: {
          type: 'boolean',
        },
        isLiveSystemAccessEnabled: {
          type: 'boolean',
        },
        isTicketingEnabled: {
          type: 'boolean',
        },
        isDeviceActionEnabled: {
          type: 'boolean',
        },
        unitOfMeasurement: {
          type: 'string',
          enum: Object.values(UnitOfMeasurement),
        },
      },
      required: [
        'isLocalSaveEnabled',
        'isCablingEnabled',
        'isUnlimitedPlansInReportsEnabled',
        'isLiveSystemAccessEnabled',
        'isTicketingEnabled',
        'isDeviceActionEnabled',
        'unitOfMeasurement',
      ],
      additionalProperties: false,
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'name', 'isActive', 'settings', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(accountSchemaLiteral)

// aggregate the document type from the schema
export type Account = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const accountSchema: RxJsonSchema<Account> = accountSchemaLiteral

export type AccountDocument = RxDocument<Account>

export type AccountCollection = RxCollection<Account>
