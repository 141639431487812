import { faChartColumn, faGear, faPenRuler, faTag, faUsers, faWrench } from '@fortawesome/free-solid-svg-icons'
import NavItem from './NavItem'
import { AdminPortal } from '@frontegg/react'

export default function OrgDrawerMenu({
  mobileOpen,
  handleDrawerToggle,
}: Readonly<{ mobileOpen: boolean; orgId: string; handleDrawerToggle: () => void }>) {
  return (
    <>
      <NavItem
        label="Accounts"
        icon={faUsers}
        mobileOpen={mobileOpen}
        href={`/accounts`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Design Projects"
        icon={faPenRuler}
        mobileOpen={mobileOpen}
        href={`/design-projects`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Installation Projects"
        icon={faWrench}
        mobileOpen={mobileOpen}
        href={`/installation-projects`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Installation Activity"
        icon={faChartColumn}
        mobileOpen={mobileOpen}
        href={`/installation-activity`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Service Tickets"
        icon={faTag}
        mobileOpen={mobileOpen}
        href={`/service-tickets`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Settings"
        icon={faGear}
        mobileOpen={mobileOpen}
        onClick={() => {
          AdminPortal.show()
        }}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  )
}
