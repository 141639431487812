import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const equipmentTypeCategorySchemaLiteral = {
  title: 'Equipment Type Category Schema',
  describe: 'Describes an equipment type category',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    name: { type: 'string' },
    order: { type: 'number' },
    pinColor: { type: 'string' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'name', 'order', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(equipmentTypeCategorySchemaLiteral)

// aggregate the document type from the schema
export type EquipmentTypeCategory = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const equipmentTypeCategorySchema: RxJsonSchema<EquipmentTypeCategory> = equipmentTypeCategorySchemaLiteral

export type EquipmentTypeCategoryDocument = RxDocument<EquipmentTypeCategory>

export type EquipmentTypeCategoryCollection = RxCollection<EquipmentTypeCategory>
