import { Box, Typography, Tabs, Tab, Divider, Link, Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import SiteBuildingsTab from './SiteBuildingsTab'
import { useRxQuery } from 'rxdb-hooks'
import { useSitesCollection } from '../app/DBProvider'
import NotFoundPage from '@/components/NotFoundPage'
import Breadcrumbs from '../layout/Breadcrumbs'
import SiteMembersTab from './SiteMembersTab'
import SiteReportsTab from './SiteReportsTab'
import SiteConnectionsTab from './SiteConnectionsTab'
import SiteFilesTab from './SiteFilesTab'
import SiteFloorsTab from './SiteFloorsTab'
import { useEffect, useState } from 'react'
import { User } from '@/lib/schemas/UserSchema'
import SiteEditModal from './SiteEditModal'
import { useBreakpoints } from '@/lib/useBreakpoints'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

export default function SitePage() {
  const { accountId, siteId, tabId = 'floors' } = useParams<{ accountId: string; siteId: string; tabId: string }>()
  const sitesCollection = useSitesCollection()

  const { result: sites, isFetching } = useRxQuery(sitesCollection.findOne(siteId))
  const site = !isFetching && sites?.length > 0 ? sites[0] : undefined

  const [isPopulated, setIsPopulated] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const { isPhone } = useBreakpoints()

  useEffect(() => {
    if (isFetching || !site) return
    const populateAssignee = async () => {
      const user = await site.populate('defaultTicketAssignedToUserId')
      ;(site as any).defaultTicketAssignedToUser = user
      setIsPopulated(true)
    }
    populateAssignee()
  }, [isFetching, site])

  if (isFetching || !isPopulated) return <></>
  if (!site) return <NotFoundPage />

  // Array of tabIds
  const tabIds = ['floors', 'buildings', 'reports', 'connections', 'users', 'files']
  // Determine the tab index based on the tabId route param
  const tabIndex = tabIds.indexOf(tabId)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true)
    handleMenuClose()
  }

  function handleCloseModal(): void {
    setIsEditModalOpen(false)
  }

  const defaultTicketAssignedToUser = (site as any).defaultTicketAssignedToUser as User
  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">{site.name}</Typography>
          {site.address && (
            <Typography variant="body2" color="textSecondary">
              {site.address}
            </Typography>
          )}
          {defaultTicketAssignedToUser && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Avatar src={defaultTicketAssignedToUser.profileImagePath} sx={{ width: 24, height: 24, mr: 1 }} />
              <Typography variant="body2" color="textSecondary">
                {defaultTicketAssignedToUser.name}
              </Typography>
            </Box>
          )}
        </Box>
        {isPhone ? (
          <>
            <IconButton onClick={handleMenuClick} sx={{ alignSelf: 'flex-start' }}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleEditClick}>Edit Site</MenuItem>
            </Menu>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={handleEditClick} sx={{ alignSelf: 'flex-start' }}>
            Edit
          </Button>
        )}
      </Box>
      <Tabs value={tabIndex} aria-label="site tabs">
        <Tab label="Floors" component={Link} href={`/accounts/${accountId}/sites/${siteId}/floors`} />
        <Tab label="Buildings" component={Link} href={`/accounts/${accountId}/sites/${siteId}/buildings`} />
        <Tab label="Reports" component={Link} href={`/accounts/${accountId}/sites/${siteId}/reports`} />
        <Tab label="Connections" component={Link} href={`/accounts/${accountId}/sites/${siteId}/connections`} />
        <Tab label="Users" component={Link} href={`/accounts/${accountId}/sites/${siteId}/users`} />
        <Tab label="Files" component={Link} href={`/accounts/${accountId}/sites/${siteId}/files`} />
      </Tabs>
      <Divider sx={{ width: '100%', mb: 2 }} />
      {tabIndex === 0 && <SiteFloorsTab />}
      {tabIndex === 1 && <SiteBuildingsTab />}
      {tabIndex === 2 && <SiteReportsTab />}
      {tabIndex === 3 && <SiteConnectionsTab />}
      {tabIndex === 4 && <SiteMembersTab />}
      {tabIndex === 5 && <SiteFilesTab />}
      <SiteEditModal open={isEditModalOpen} onClose={handleCloseModal} site={site} />
    </>
  )
}
