import { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material'
import { useFloorsCollection } from '../app/DBProvider'
import CloseIcon from '@mui/icons-material/Close'
import { useBreakpoints } from '@/lib/useBreakpoints'

interface NewFloorModalProps {
  open: boolean
  onClose: () => void
  onCreate: (floor: any) => void
  accountId: string
  siteId: string
  buildingId: string
}

export default function NewFloorModal({
  open,
  onClose,
  onCreate,
  accountId,
  siteId,
  buildingId,
}: Readonly<NewFloorModalProps>) {
  const floorsCollection = useFloorsCollection()
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isPhone } = useBreakpoints()

  const handleCreate = async () => {
    setIsSubmitting(true)
    try {
      const newFloor = await floorsCollection.insert({
        id: crypto.randomUUID(),
        orgId: '', // Set appropriate orgId
        accountId,
        siteId,
        buildingId,
        planImagePath: '', // Set appropriate planImagePath
        name,
        createdDate: new Date().toISOString(),
        createdByUserId: '', // Set appropriate userId
        updatedDate: new Date().toISOString(),
        updatedByUserId: '', // Set appropriate userId
        orderNumber: 0,
        isArchived: false,
        _deleted: false,
      })
      onCreate(newFloor)
      onClose()
    } catch (error) {
      console.error('Failed to create floor:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" fullScreen={isPhone}>
      <DialogTitle>
        Create New Floor
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField label="Floor Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth required />
          <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={handleCreate} variant="contained" color="primary" disabled={isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
