import { Typography } from '@mui/material'
import React from 'react'

export default function NoRowsOverlay({ message }: Readonly<{ message: string }>) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Typography variant="body1" color="textSecondary">
        {message}
      </Typography>
    </div>
  )
}
