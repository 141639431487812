import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum AMTFieldView {
  photo_review = 'photo_review',
  default = 'default',
}

export enum ProjectFieldView {
  photo_review = 'photo_review',
  default = 'default',
  flag_review = 'flag_review',
  punch_list = 'punch_list',
  completed_work = 'completed_work',
}

const userAccountPreferencesSchemaLiteral = {
  title: 'User Account Preferences Schema',
  describe: 'Describes user preferences for a account',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    userId: {
      type: 'string',
      ref: 'users',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    amtDeviceField: {
      type: 'string',
    },
    projectDeviceField: {
      type: 'string',
    },
    serviceTicketField: {
      type: 'string',
    },
    projectDevicePunchListField: {
      type: 'string',
    },
    isProjectDevicePunchList: {
      type: 'boolean',
    },
    amtDevicePhotoReviewField: {
      type: 'string',
    },
    projectDevicePhotoReviewField: {
      type: 'string',
    },
    amtFieldView: {
      type: 'string',
      enum: Object.values(AMTFieldView),
    },
    projectFieldView: {
      type: 'string',
      enum: Object.values(ProjectFieldView),
    },
    projectDeviceCompletedWorkField: {
      type: 'string',
    },
    projectDeviceFlagReviewField: {
      type: 'string',
    },
    projectReportField: {
      type: 'string',
    },
    livesiteReportField: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'userId', 'accountId', 'amtFieldView', 'projectFieldView', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(userAccountPreferencesSchemaLiteral)

// aggregate the document type from the schema
export type UserAccountPreferences = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const userAccountPreferencesSchema: RxJsonSchema<UserAccountPreferences> = userAccountPreferencesSchemaLiteral

export type UserAccountPreferencesDocument = RxDocument<UserAccountPreferences>

export type UserAccountPreferencesCollection = RxCollection<UserAccountPreferences>
