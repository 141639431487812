import { useTheme, useMediaQuery } from '@mui/material'

export interface BreakpointValues {
  isPhone: boolean
  isTablet: boolean
  isDesktop: boolean
  isSmallScreen: boolean
}

export function useBreakpoints(): BreakpointValues {
  const theme = useTheme()

  // Phone: down from md (960px)
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  // Tablet: up from md (960px)
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))

  // Desktop: up from lg (1280px)
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  // Small screen: down from sm (600px)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return {
    isPhone,
    isTablet,
    isDesktop,
    isSmallScreen,
  }
}
