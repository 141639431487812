import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { filter, firstValueFrom, mergeMap } from 'rxjs'
import { useFirebaseAuthState } from './FirebaseUserProvider'
import { useFronteggAuthState } from './FronteggUserProvider'
import { SiteOwlDatabase, useDBState } from './DBProvider'
import { getAuth } from 'firebase/auth'
import _ from 'lodash'
import { RxCollection } from 'rxdb'
import { replicateRxCollection, RxReplicationState } from 'rxdb/plugins/replication'
import { useSetting } from '@/lib/hooks'

const GLOBAL_COLLECTIONS = ['users', 'orgs', 'equipment_type_categories', 'equipment_types', 'task_types']

const ACCOUNT_SPECIFIC_COLLECTIONS = [
  'buildings',
  'accounts',
  'account_members',
  'devices',
  'device_versions',
  'favorite_devices',
  'favorite_tasks',
  'favorites',
  'floors',
  'locations',
  'project_files',
  'project_floors',
  'project_members',
  'projects',
  'service_ticket_comments',
  'service_ticket_file_attachments',
  'service_ticket_watchers',
  'service_tickets',
  'site_files',
  'site_members',
  'sites',
  'task_images',
  'tasks',
  'user_account_preferences',
  'user_floor_preferences',
]

type DBSyncState = {
  syncEnabled: boolean
  setSyncEnabled: (enabled: boolean) => void
  firstSyncIsLoading: boolean
  addAccountToSync: (accountId: string) => Promise<void>
  syncedAccountIds: string[]
}

export const DBSyncContext = createContext<DBSyncState>({
  syncEnabled: true,
  setSyncEnabled: () => {},
  firstSyncIsLoading: true,
  addAccountToSync: async () => {},
  syncedAccountIds: [],
})

export default function DBSyncProvider({
  defaultSyncEnabled = true,
  defaultSyncIsLoading = true,
  children,
}: Readonly<{
  defaultSyncEnabled?: boolean
  defaultSyncIsLoading?: boolean
  children: React.ReactNode
}>) {
  const { db } = useDBState()
  const [syncedAccountIds, setSyncedAccountIds] = useSetting<string[]>('synced-accounts', [])
  const [syncEnabled, setSyncEnabled] = useSetting<boolean>('sync-enabled', defaultSyncEnabled)
  const [firstSyncIsLoading, setFirstSyncIsLoading] = useState(defaultSyncIsLoading)
  const [replicationStates, setReplicationStates] = useState<RxReplicationState<any, Checkpoint>[]>([])
  const {
    firebaseAuth: { firebaseUserId, firebaseAuthIsLoading },
  } = useFirebaseAuthState()
  const { fronteggAuth } = useFronteggAuthState()

  const addAccountToSync = useCallback(
    async (accountId: string) => {
      if (!db || syncedAccountIds.includes(accountId)) return

      // Create a temporary catch-up replication for each account specific collection
      const catchupStatePromises = ACCOUNT_SPECIFIC_COLLECTIONS.map(async (collectionName) => {
        return createReplicationState(db, collectionName, {
          replicationIdentifier: `http-${accountId}-catchup-${collectionName}-${Date.now()}`,
          getAccountIds: () => [accountId],
          live: false, // it will cancel itself when the catch-up is complete
          waitForLeadership: true,
          autoStart: true, // go ahead and start the catch-up replication regardless of sync enabled
        })
      })

      // Wait for the catch-up replications to start
      const catchupStates = await Promise.all(catchupStatePromises)

      // Wait for the catch-up replications to complete
      const lastSyncDuration = 1000 * 60
      await Promise.all(
        catchupStates.map((state) =>
          firstValueFrom(
            state.collection
              .getLocal$('last-in-sync')
              .pipe(filter((d: any) => d.get('time') > Date.now() - lastSyncDuration)),
          ),
        ),
      )

      // Add the account to the synced accounts
      await setSyncedAccountIds([...syncedAccountIds, accountId])
    },
    [db, syncedAccountIds, setSyncedAccountIds],
  )

  useEffect(() => {
    const initializeSync = async () => {
      if (!db) {
        console.log('DBSyncProvider: db still initializing, wait to sync')
        return
      }

      if (firebaseAuthIsLoading) {
        console.log('DBSyncProvider: firebase auth is still loading, just wait longer')
        return
      }

      if (!firebaseUserId) {
        console.log('DBSyncProvider: firebase is done loading, but we dont have a userId, so nothing to sync yet')
        setFirstSyncIsLoading(false)
        return
      }

      console.log('DBSyncProvider: db and firestore are initialized, so initialize sync')

      // initialize all replications in parallel
      const replicationStatePromises = [
        ...GLOBAL_COLLECTIONS.map(async (collectionName) => {
          console.log(`syncing ${collectionName}`)
          return createReplicationState(db, collectionName, {
            replicationIdentifier: `http-${collectionName}`,
          })
        }),
        ...ACCOUNT_SPECIFIC_COLLECTIONS.map(async (collectionName) => {
          console.log(`syncing ${collectionName}`)
          return createReplicationState(db, collectionName, {
            replicationIdentifier: `http-${collectionName}`,
            getAccountIds: () => syncedAccountIds,
            fronteggTenantId: fronteggAuth.fronteggTenantId,
          })
        }),
      ]

      const states = await Promise.all(replicationStatePromises)
      setReplicationStates(states)

      console.log('DBSyncProvider: initializeSync complete')
    }

    initializeSync()
  }, [db, firebaseAuthIsLoading, firebaseUserId, fronteggAuth, syncedAccountIds])

  useEffect(() => {
    const toggleSync = async () => {
      if (replicationStates.length < 1) {
        console.log('DBSyncProvider: no syncs initialized, so skip toggling sync')
        return
      }

      if (syncEnabled) {
        console.log('DBSyncProvider: sync is enabled, so starting all syncs')
        replicationStates.forEach(async (state) => await state.start())
        console.log('DBSyncProvider: syncs started')

        if (firstSyncIsLoading) {
          console.log('DBSyncProvider: first time enabling sync, waiting for initial sync')
          const lastSyncDuration = 1000 * 60
          await Promise.all(
            replicationStates.map((state) =>
              firstValueFrom(
                state.collection
                  .getLocal$('last-in-sync')
                  .pipe(filter((d: any) => d.get('time') > Date.now() - lastSyncDuration)),
              ),
            ),
          )
          console.log('DBSyncProvider: initial sync is complete')
          setFirstSyncIsLoading(false)
        } else {
          console.log('DBSyncProvider: not first time enabling sync, skipping wait')
        }
      } else {
        await Promise.all(replicationStates.map((state) => state.cancel()))
      }
    }

    toggleSync()
  }, [syncEnabled, replicationStates, firstSyncIsLoading])

  console.log(`DBSyncProvider: rendering firstSyncIsLoading=${firstSyncIsLoading} syncEnabled=${syncEnabled}  `)

  const value = useMemo(() => {
    return {
      syncEnabled,
      firstSyncIsLoading,
      setSyncEnabled: async (enabled: boolean) => {
        console.log(`DBSyncProvider: setSyncEnabled(${enabled})`)
        await setSyncEnabled(enabled)
      },
      addAccountToSync,
      syncedAccountIds,
    }
  }, [syncEnabled, firstSyncIsLoading, setSyncEnabled, addAccountToSync, syncedAccountIds])

  return <DBSyncContext.Provider value={value}>{children}</DBSyncContext.Provider>
}

export const useDBSyncState = () => {
  return useContext(DBSyncContext)
}

interface Checkpoint {
  id: string
  updatedAt: number
}

async function createReplicationState(
  db: SiteOwlDatabase,
  collectionName: string,
  options: {
    replicationIdentifier: string
    getAccountIds?: () => string[]
    live?: boolean
    waitForLeadership?: boolean
    autoStart?: boolean
    fronteggTenantId?: string
  },
): Promise<RxReplicationState<any, Checkpoint>> {
  const rxdbCollection = _.get(db, collectionName) as RxCollection<any>
  const baseUrl = import.meta.env.VITE_CLOUD_FUNCTIONS_ROOT.replace('{function-name}', 'rxdb')
  const pullUrl = options.fronteggTenantId
    ? `${baseUrl}/pull/orgs/${options.fronteggTenantId}/${collectionName}`
    : `${baseUrl}/pull/${collectionName}`

  const replicationState = replicateRxCollection<any, Checkpoint>({
    replicationIdentifier: options.replicationIdentifier,
    collection: rxdbCollection,
    pull: {
      async handler(lastPulledCheckpoint, batchSize: number) {
        const updatedAt = lastPulledCheckpoint ? lastPulledCheckpoint.updatedAt : 0
        const id = lastPulledCheckpoint ? lastPulledCheckpoint.id : ''
        const authToken = await getAuthToken()
        const response = await fetch(pullUrl, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            updatedAt,
            id,
            batchSize,
            accountIds: options.getAccountIds ? options.getAccountIds() : [],
          }),
        })
        const data = await response.json()
        data.documents = data.documents.map((doc: any) => {
          delete doc.serverTimestamp
          return doc
        })
        return {
          documents: data.documents,
          checkpoint: data.checkpoint,
        }
      },
      batchSize: 100,
    },
    live: options.live ?? true,
    waitForLeadership: options.waitForLeadership ?? true,
    autoStart: options.autoStart ?? false,
  })

  replicationState.received$.subscribe((doc) => console.log(`received ${collectionName}`, doc))
  replicationState.sent$.subscribe((doc) => console.log('sent', doc))
  replicationState.error$.subscribe((error) => console.log('error', collectionName, error))
  replicationState.canceled$.subscribe((bool) => console.log('canceled', bool))
  replicationState.active$.subscribe((bool) => console.log(`active ${collectionName}`, bool))
  replicationState.active$
    .pipe(
      mergeMap(async () => {
        await replicationState.awaitInSync()
        await rxdbCollection.upsertLocal('last-in-sync', { time: Date.now() })
      }),
    )
    .subscribe()

  return replicationState
}

// Helper function to get the auth token
async function getAuthToken(): Promise<string> {
  const auth = getAuth()
  return (await auth.currentUser?.getIdToken()) ?? ''
}
