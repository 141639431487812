import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material'
import { LinkProps } from '@mui/material/Link'
import React, { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

export enum ColorMode {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)
LinkBehavior.displayName = 'LinkBehavior'

export default function ThemeModeProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [cookies] = useCookies(['colorMode'])
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = useMemo(() => {
    const colorMode = (cookies.colorMode as ColorMode) || ColorMode.System
    let mode: ColorMode
    if (colorMode === ColorMode.System) {
      mode = prefersDarkMode ? ColorMode.Dark : ColorMode.Light
    } else {
      mode = colorMode
    }

    const baseTheme = {
      typography: {
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        h1: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h1 bold
        },
        h2: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h2 bold
        },
        h3: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h3 bold
        },
        h4: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h4 bold
        },
        h5: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h5 bold
        },
        h6: {
          fontFamily: 'Sofia Pro, Arial, sans-serif',
          fontWeight: 'bold', // Make h6 bold
        },
      },
      components: {
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
          styleOverrides: {
            root: {
              textDecoration: 'none', // Remove underline
              '&:hover': {
                textDecoration: 'underline', // Optional: Add underline on hover
              },
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
            disableRipple: true,
          },
        },
      },
    }

    if (mode === ColorMode.Dark) {
      return createTheme({
        palette: {
          mode: 'dark',
        },
        ...baseTheme,
      })
    } else {
      return createTheme({
        palette: {
          background: {
            default: '#ffffff',
          },
          mode: 'light',
          primary: {
            main: '#009AF9',
          },
          secondary: {
            main: '#00B2F8',
          },
          text: {
            primary: '#0d0d0d',
          },
          error: {
            main: '#EA5E86',
          },
          warning: {
            main: '#F7E492',
          },
          success: {
            main: '#4EDD8E',
          },
          info: {
            main: '#009AF9',
          },
        },
        ...baseTheme,
      })
    }
  }, [cookies.colorMode, prefersDarkMode])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
