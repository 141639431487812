import { Box } from '@mui/material'
import PageHeader from '../lib/PageHeader'
import Breadcrumbs from '../layout/Breadcrumbs'
import DashboardPage from '../dashboard/DashboardPage'

export default function AccountLivePlanningPage() {
  return (
    <>
      <Breadcrumbs />
      <Box sx={{ mb: 2 }}>
        <PageHeader title="Account Live Planning" description="" />
      </Box>
      <DashboardPage />
    </>
  )
}
