import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum DeviceStatus {
  unpublished = 'unpublished',
  published = 'published',
  removed = 'removed',
  archived = 'archived',
}

const deviceSchemaLiteral = {
  title: 'Device Schema',
  describe: 'Describes a device',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    siteId: {
      type: 'string',
      ref: 'sites',
    },
    buildingId: {
      type: 'string',
      ref: 'buildings',
    },
    floorId: {
      type: 'string',
      ref: 'floors',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
      ref: 'users',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    status: {
      type: 'string',
      enum: Object.values(DeviceStatus),
    },
    orderWithinLocation: {
      type: 'number',
    },
    currentVersionId: {
      type: 'string',
      ref: 'device_versions',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'status', 'currentVersionId', 'orderWithinLocation', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(deviceSchemaLiteral)

// aggregate the document type from the schema
export type Device = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const deviceSchema: RxJsonSchema<Device> = deviceSchemaLiteral

export type DeviceDocument = RxDocument<Device>

export type DeviceCollection = RxCollection<Device>
