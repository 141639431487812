import React, { useState } from 'react'
import { Menu, MenuItem, Button, ListItemIcon, ListItemText } from '@mui/material'
import { ProjectStatus } from '@/lib/schemas/ProjectSchema'
import { useNavigate } from 'react-router'
import { statusMap } from '@/lib/utils'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import { useSearchParams } from 'react-router-dom' // Import useSearchParams

export default function ProjectStatusFilter() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams() // Use useSearchParams hook
  const selectedStatus = searchParams.get('status') ?? ''
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (status: string) => {
    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }
    navigate({ search: searchParams.toString() })
    handleClose()
  }

  return (
    <>
      <Button
        color={selectedStatus ? 'primary' : 'inherit'}
        variant={selectedStatus ? 'contained' : 'text'}
        size="small"
        sx={{
          textTransform: 'none',
          boxShadow: 'none',
          backgroundColor: selectedStatus ? 'rgba(0, 154, 249, 0.1)' : undefined,
          color: selectedStatus ? 'rgba(0, 154, 249)' : 'inherit',
          fontWeight: selectedStatus ? 'bold' : 'normal',
        }}
        onClick={handleClick}
      >
        {selectedStatus ? statusMap[selectedStatus as ProjectStatus] : 'Status'}
        <ArrowDropDownIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.values(ProjectStatus).map((status) => (
          <MenuItem key={status} selected={status === selectedStatus} onClick={() => handleMenuItemClick(status)}>
            <ListItemText sx={{ marginRight: '32px' }}>{statusMap[status]}</ListItemText>
            <ListItemIcon>{status === selectedStatus && <CheckIcon fontSize="small" />}</ListItemIcon>
          </MenuItem>
        ))}
        {selectedStatus && (
          <MenuItem onClick={() => handleMenuItemClick('')} sx={{ fontSize: '0.7rem', color: '#003F77' }}>
            Clear Selected
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
