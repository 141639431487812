import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useLogout } from '../app/FirebaseUserProvider'
import { AdminPortal } from '@frontegg/react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { useUser } from '../app/UserProvider'
import { Divider, ListItemText, Switch } from '@mui/material'
import { useDBSyncState } from '../app/DBSyncProvider'
import MyProfileModal from './MyProfileModal'

export default function ProfileMenu() {
  const { syncEnabled, setSyncEnabled } = useDBSyncState()
  const currentUser = useUser()
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const logout = useLogout()
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogoutClick = () => {
    logout()
    handleCloseUserMenu()
  }

  const handleToggleSync = () => {
    console.log('handleToggleSync', syncEnabled)
    setSyncEnabled(!syncEnabled)
  }

  const handleOpenProfileModal = () => {
    setIsProfileModalOpen(true)
    handleCloseUserMenu()
  }

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false)
  }

  function stringAvatar(name: string) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Account Settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={currentUser.name}
            src={currentUser.profileImagePath?.includes('random') ? undefined : currentUser.profileImagePath}
            {...stringAvatar(currentUser.name)}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px', width: 320 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleOpenProfileModal}>
          <ListItemText>My Profile</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseUserMenu()
            AdminPortal.show()
          }}
        >
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText primary={syncEnabled ? 'Online' : 'Offline'} />
          <Switch checked={syncEnabled} onChange={handleToggleSync} sx={{ marginLeft: 5 }} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutClick}>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
      <MyProfileModal open={isProfileModalOpen} onClose={handleCloseProfileModal} />
    </Box>
  )
}
