import {
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPaginationModelSelector,
  gridRowCountSelector,
} from '@mui/x-data-grid'
import { useBreakpoints } from '@/lib/useBreakpoints'
import { Box, Button, Typography } from '@mui/material'

export default function CustomPagination(props: any) {
  const { isPhone } = useBreakpoints()
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
