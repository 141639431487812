import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum ServiceTicketStatus {
  open = 'open',
  closed = 'closed',
  in_progress = 'in_progress',
}

export enum ServiceTicketPriority {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

const serviceTicketSchemaLiteral = {
  title: 'Service Ticket Schema',
  describe: 'Describes a service ticket',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    subject: {
      type: 'string',
    },
    requestedByUserId: {
      type: 'string',
      ref: 'users',
    },
    status: {
      type: 'string',
      enum: Object.values(ServiceTicketStatus),
    },
    priority: {
      type: 'string',
      enum: Object.values(ServiceTicketPriority),
    },
    floorId: {
      type: 'string',
      ref: 'floors',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
      ref: 'users',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
      ref: 'users',
    },
    description: {
      type: 'string',
    },
    assignedToUserId: {
      type: 'string',
      ref: 'users',
    },
    dueDate: {
      type: 'string',
      format: 'date-time',
    },
    closedDate: {
      type: 'string',
      format: 'date-time',
    },
    cost: {
      type: 'number',
    },
    isArchived: {
      type: 'boolean',
    },
    scheduledDate: {
      type: 'string',
      format: 'date-time',
    },
    deviceIds: {
      type: 'array',
      items: {
        type: 'string',
        ref: 'devices',
      },
    },
    quote: {
      type: 'object',
      properties: {
        preparedFor: {
          type: 'string',
        },
        preparedBy: {
          type: 'string',
        },
        taxPercentage: {
          type: 'number',
        },
        createdDate: {
          type: 'string',
          format: 'date-time',
        },
        createdByUserId: {
          type: 'string',
        },
        updatedDate: {
          type: 'string',
          format: 'date-time',
        },
        updatedByUserId: {
          type: 'string',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              item: {
                type: 'string',
              },
              quantity: {
                type: 'number',
              },
              rate: {
                type: 'number',
              },
              amount: {
                type: 'number',
              },
              note: {
                type: 'string',
              },
              tax: {
                type: 'boolean',
              },
              include: {
                type: 'boolean',
              },
              createdDate: {
                type: 'string',
                format: 'date-time',
              },
              createdByUserId: {
                type: 'string',
              },
              updatedDate: {
                type: 'string',
                format: 'date-time',
              },
              updatedByUserId: {
                type: 'string',
              },
            },
            required: ['item', 'quantity', 'rate', 'amount', 'tax', 'include'],
            additionalProperties: false,
          },
        },
      },
      required: ['taxPercentage', 'lineItems'],
      additionalProperties: false,
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'subject',
    'requestedByUserId',
    'status',
    'priority',
    'floorId',
    'deviceIds',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(serviceTicketSchemaLiteral)

// aggregate the document type from the schema
export type ServiceTicket = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>
export type ServiceTicketQuote = ServiceTicket['quote']
export type ServiceTicketQuoteLineItem = NonNullable<ServiceTicketQuote>['lineItems'][0]

// create the typed RxJsonSchema from the literal typed object.
export const serviceTicketSchema: RxJsonSchema<ServiceTicket> = serviceTicketSchemaLiteral

export type ServiceTicketDocument = RxDocument<ServiceTicket>

export type ServiceTicketCollection = RxCollection<ServiceTicket>
