import { Box, Typography, Button, Tabs, Tab, Divider, useTheme, IconButton, Menu, MenuItem, Link } from '@mui/material'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import NewDeviceModal from '../device/NewDeviceModal'
import DevicesTable from '../device/DevicesTable'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { RxDocument } from 'rxdb'
import Breadcrumbs from '../layout/Breadcrumbs'

export interface DevicesPageProps {
  hasProject?: boolean
  title: string
  description: string
  buttonLabel: string
  columns: GridColDef<RxDocument<DeviceVersion>>[]
  summaryBlock?: React.ReactNode
}

export default function DevicesPage({
  hasProject = false,
  title,
  description,
  buttonLabel,
  columns,
  summaryBlock,
}: Readonly<DevicesPageProps>) {
  const { accountId, tabId } = useParams<{ accountId: string; tabId: string }>()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()

  // Determine the tab index based on the tabId route param
  const tabIndex = tabId === 'archived' ? 1 : 0

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateDevice = (device: any) => {
    // Navigate to the newly created device's page
    navigate(`/accounts/${device.accountId}/devices/${device.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewDeviceClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {accountId && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleOpenModal}
              sx={{
                display: { xs: 'none', md: 'inline-flex' },
                whiteSpace: 'nowrap',
                borderColor: theme.palette.text.secondary,
                color: theme.palette.text.secondary,
              }}
            >
              {buttonLabel}
            </Button>
          )}
          <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
            <IconButton onClick={handleMenuOpen}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleMenuNewDeviceClick}>New Device</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      {summaryBlock}
      {accountId ? (
        <>
          <Tabs
            value={tabIndex}
            aria-label="devices tabs"
            sx={{
              minHeight: 32, // Adjust height
              '& .MuiTab-root': {
                minHeight: 32, // Reduce tab height
                fontSize: '0.75rem', // Smaller font size
                padding: '4px 8px', // Reduce padding
              },
            }}
          >
            <Tab label="Active" component={Link} href={`/accounts/${accountId}/devices`} />
            <Tab label="Archived" component={Link} href={`/accounts/${accountId}/devices/archived`} />
          </Tabs>
          <Divider sx={{ width: '100%', mb: 2 }} /> {/* Add solid line with margin bottom */}
          {tabIndex === 0 && <DevicesTable hasProject={hasProject} columns={columns} />}
        </>
      ) : (
        <DevicesTable hasProject={hasProject} columns={columns} />
      )}
      {accountId && (
        <NewDeviceModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onCreate={handleCreateDevice}
          accountId={accountId}
        />
      )}
    </>
  )
}
