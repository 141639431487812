import { Org } from '@/lib/schemas/OrgSchema'
import { User } from '@/lib/schemas/UserSchema'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import * as Sentry from '@sentry/browser'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useDBState } from './DBProvider'
import { useDBSyncState } from './DBSyncProvider'
import { useFirebaseAuthState } from './FirebaseUserProvider'
import { useFronteggAuthState } from './FronteggUserProvider'

type UserContextState = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  user: User | undefined
  setUser: (user: User | undefined) => void
  currentOrg: Org | undefined
  setCurrentOrg: (org: Org | undefined) => void
  orgs: Org[] | undefined
  setOrgs: (orgs: Org[] | undefined) => void
}

export const UserContext = createContext<UserContextState>({
  isLoading: true,
  setIsLoading: () => {
    /* do nothing */
  },
  user: undefined,
  setUser: () => {
    /* do nothing */
  },
  currentOrg: undefined,
  setCurrentOrg: () => {
    /* do nothing */
  },
  orgs: undefined,
  setOrgs: () => {
    /* do nothing */
  },
})

export default function UserProvider({
  defaultUser = undefined,
  defaultCurrentOrg = undefined,
  defaultIsLoading = true,
  children,
}: Readonly<{
  defaultUser?: User
  defaultCurrentOrg?: Org
  defaultIsLoading?: boolean
  children: React.ReactNode
}>) {
  const [isLoading, setIsLoading] = useState<boolean>(defaultIsLoading)
  const [user, setUser] = useState<User | undefined>(defaultUser)
  const [currentOrg, setCurrentOrg] = useState<Org | undefined>(defaultCurrentOrg)
  const [orgs, setOrgs] = useState<Org[] | undefined>(undefined)
  const {
    fronteggAuth: { fronteggTenantId, fronteggTenantIds },
  } = useFronteggAuthState()
  const {
    firebaseAuth: { firebaseAuthIsLoading, firebaseUserId },
  } = useFirebaseAuthState()
  const { db, dbIsReady } = useDBState()
  const { firstSyncIsLoading } = useDBSyncState()

  useEffect(() => {
    console.log(`UP: useEffect firebaseAuthIsLoading=${firebaseAuthIsLoading} firebaseUserId=${firebaseUserId}`)
    if (firebaseAuthIsLoading) {
      console.log('UP: firebase user is still loading.  just wait longer')
      return
    }

    if (!dbIsReady) {
      console.log('UP: db is still loading.  just wait longer')
      return
    }

    if (firstSyncIsLoading) {
      console.log('UP: db sync is still loading.  just wait longer')
      return
    }

    const initializeUserState = async () => {
      console.log(`UP: in initializeUserState firebaseUserId=${firebaseUserId}`)
      if (!firebaseUserId || !db) {
        console.log(`UP: not logged in to firebase or db was not initialized.  we are completely logged out.`)
        setUser(undefined)
        setCurrentOrg(undefined)
        setOrgs(undefined)
        setIsLoading(false)
        Sentry.setUser(null)
        await FirebaseAnalytics.setUserId({ userId: null })
        return
      }

      console.log(`UP: logged in to firebase, fetching user from rxdb`)

      const userFromStore = await db.users.findOne().where('fronteggUserId').equals(firebaseUserId).exec()

      if (!userFromStore) throw new Error(`logged in user is missing a user record in rxdb ${firebaseUserId}`)
      console.log(`UP: userFromStore=${userFromStore.id}`)

      // set the user for GA
      await FirebaseAnalytics.setUserId({ userId: userFromStore.id })

      // set the user for Sentry
      Sentry.setUser({ id: userFromStore.id, email: userFromStore.email })

      console.log(`UP: user loaded from store, fetching tenant from fronteggTenantIds=${fronteggTenantIds}`)
      const orgs = await db.orgs
        .find()
        .where('fronteggId')
        .in(fronteggTenantIds || [])
        .exec()

      // find the current org
      const currentOrg = orgs.find((org) => org.fronteggId === fronteggTenantId)
      console.log(`UP: currentOrg=${currentOrg?.id}`)
      if (!currentOrg) throw new Error(`current org is missing from orgs ${fronteggTenantId}`)

      console.log('UP: setting userFromStore', userFromStore)
      setUser(userFromStore)
      setCurrentOrg(currentOrg)
      setOrgs(orgs)
      setIsLoading(false)
    }

    initializeUserState()
  }, [
    firebaseAuthIsLoading,
    firebaseUserId,
    setIsLoading,
    setUser,
    fronteggTenantId,
    fronteggTenantIds,
    dbIsReady,
    db,
    firstSyncIsLoading,
  ])

  console.log(`UP: rendering isLoading=${isLoading} user=${user?.id}`)
  const value = useMemo(
    () => ({ isLoading, setIsLoading, user, setUser, currentOrg, setCurrentOrg, orgs, setOrgs }),
    [isLoading, user, setIsLoading, setUser, currentOrg, setCurrentOrg, orgs, setOrgs],
  )
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserState = (): UserContextState => {
  return useContext(UserContext)
}

export const useUser = (): User => {
  const { user } = useContext(UserContext)
  if (!user) throw new Error("Can't call useUser before the user context is loaded")
  return user
}

export const useCurrentOrg = (): Org => {
  const { currentOrg } = useContext(UserContext)
  if (!currentOrg) throw new Error("Can't call useCurrentOrg before the user context is loaded")
  return currentOrg
}

export const useOrgs = (): Org[] => {
  const { orgs } = useContext(UserContext)
  if (!orgs) throw new Error("Can't call useOrgs before the user context is loaded")
  return orgs
}
