import React, { useState } from 'react'
import { Modal, Box, Typography, TextField, Button, MenuItem, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Device, DeviceStatus } from '@/lib/schemas/DeviceSchema'
import { useDevicesCollection } from '../app/DBProvider'
import * as uuid from 'uuid'
import { useCurrentOrg } from '../app/UserProvider'
import { useBreakpoints } from '@/lib/useBreakpoints'

interface NewDeviceModalProps {
  open: boolean
  onClose: () => void
  onCreate: (device: any) => void
  accountId: string
}

const NewDeviceModal: React.FC<NewDeviceModalProps> = ({ open, onClose, onCreate, accountId }) => {
  const currentOrg = useCurrentOrg()
  const [deviceName, setDeviceName] = useState('')
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>(DeviceStatus.unpublished)
  const [siteId, setSiteId] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [floorId, setFloorId] = useState('')
  const [description, setDescription] = useState('')
  const devicesCollection = useDevicesCollection()
  const { isSmallScreen } = useBreakpoints()

  const handleCreate = async () => {
    const newDevice: Device = {
      id: uuid.v4(),
      orgId: currentOrg.id,
      accountId,
      status: deviceStatus,
      siteId,
      buildingId,
      floorId,
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      orderWithinLocation: 0,
      currentVersionId: '', // This will be set when creating the first version
      _deleted: false,
    }
    await devicesCollection.insert(newDevice)
    onCreate(newDevice)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, ...(isSmallScreen && modalFullScreenStyle) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            New Device
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Device Name"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          select
          label="Device Status"
          value={deviceStatus}
          onChange={(e) => setDeviceStatus(e.target.value as DeviceStatus)}
          fullWidth
          margin="normal"
        >
          {Object.values(DeviceStatus).map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Site ID"
          value={siteId}
          onChange={(e) => setSiteId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Building ID"
          value={buildingId}
          onChange={(e) => setBuildingId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Floor ID"
          value={floorId}
          onChange={(e) => setFloorId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create Device
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const modalFullScreenStyle = {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  transform: 'none',
  display: 'flex',
  flexDirection: 'column',
  p: 2,
}

export default NewDeviceModal
