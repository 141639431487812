import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useTasksCollection } from '../app/DBProvider'
import TaskImagesTab from './TaskImagesTab'
import TaskInfoTab from './TaskInfoTab'

const tabValues = ['info', 'images']

export default function TaskPage() {
  const tasksCollection = useTasksCollection()
  const { taskId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0

  const { result: tasks, isFetching } = useRxQuery(tasksCollection.findOne(taskId))

  if (isFetching) return <></>

  const task = tasks?.[0]
  if (!task) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {task.name}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/tasks/${taskId}`} />
        <Tab label="Images" component={Link} href={`/tasks/${taskId}/images`} />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {tabIndex === 0 && <TaskInfoTab task={task} />}
        {tabIndex === 1 && <TaskImagesTab task={task} />}
      </Box>
    </>
  )
}
