import { Box } from '@mui/material'

export default function NotFoundPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      404 - Page Not Found
    </Box>
  )
}
