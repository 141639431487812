import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useRegisterSW } from 'virtual:pwa-register/react'
import Button from '@mui/material/Button'

export default function UpdateButton() {
  const location = useLocation()
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>(undefined)
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r: ServiceWorkerRegistration | undefined) {
      console.log('registered service worker')
      if (!r) return
      setRegistration(r)
    },
    onRegisterError(error: any) {
      console.log('SW registration error', error)
    },
  })

  useEffect(() => {
    if (!registration) return
    registration.update()
  }, [location, registration])

  return (
    <>
      {needRefresh && (
        <Button
          onClick={() => {
            try {
              console.log('start update service worker')
              updateServiceWorker(true)
              console.log('end update service worker')
            } catch (e) {
              console.error('updateServiceWorker error', e)
            }
          }}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: '20px' }}
        >
          Update Available
        </Button>
      )}
    </>
  )
}
