import { Box } from '@mui/material'
import PageHeader from '../lib/PageHeader'
import Breadcrumb from '../layout/Breadcrumbs'
import DashboardPage from '../dashboard/DashboardPage'

export default function OrgInstallationActivityPage() {
  return (
    <>
      <Breadcrumb />
      <Box sx={{ mb: 2 }}>
        <PageHeader
          title="Installation Activity"
          description="Headquarters Users shows all Users associated with your Headquarters."
        />
      </Box>
      <DashboardPage />
    </>
  )
}
