import ProjectsPage from '../lib/ProjectsPage'
import { Project, ProjectStage } from '@/lib/schemas/ProjectSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Typography, Avatar, Card, CardContent, Grid2, Link } from '@mui/material'
import ProjectStatusChip from '../project/ProjectStatusChip'
import { RxDocument } from 'rxdb'
import { User } from '@/lib/schemas/UserSchema'
import { useBreakpoints } from '@/lib/useBreakpoints'

export default function AccountDesignProjectsPage() {
  const { isTablet, isDesktop, isPhone } = useBreakpoints()

  const columns: GridColDef<RxDocument<Project>>[] = [
    {
      field: 'name',
      headerName: 'Project',
      flex: 2,
      renderCell: (params) => {
        const project = params.row
        const user = (project as any).designerUser as User
        const account = (project as any).account
        return (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <Box>
                <Link variant="body1" href={`/accounts/${account?.id}/projects/${project.id}`}>
                  {params.value}
                </Link>
                <Typography variant="caption" color="textSecondary" sx={{ display: { xs: 'block', md: 'none' } }}>
                  {account?.name ?? 'Unknown'}
                </Typography>
                <Typography variant="caption" color="textSecondary" sx={{ display: { xs: 'block', md: 'none' } }}>
                  4 Plans | 3 Flags | 16 Devices | 0 Tasks | 0 Budgeted Hours
                </Typography>
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <ProjectStatusChip status={params.row.designStatus} />
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, paddingTop: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={user?.profileImagePath} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
                <Typography color="textSecondary">{user?.name ?? 'Unknown'}</Typography>
              </Box>
            </Box>
          </Box>
        )
      },
    },
  ]

  if (!isPhone) {
    columns.push({
      field: 'account',
      headerName: 'Account',
      flex: 2,
      renderCell: (params) => {
        const project = params.row
        const account = (project as any).account
        return account ? (
          <Link href={`/accounts/${account.id}`} variant="body1">
            {account.name}
          </Link>
        ) : (
          <Typography>Unknown</Typography>
        )
      },
    })
  }

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography>4</Typography>, // Hardcoded count for now
      },
      {
        field: 'flags',
        headerName: 'Flags',
        flex: 1,
        renderCell: () => <Typography>3</Typography>, // Hardcoded count for now
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography>16</Typography>, // Hardcoded count for now
      },
      {
        field: 'tasks',
        headerName: 'Tasks',
        flex: 1,
        renderCell: () => <Typography>0</Typography>, // Hardcoded count for now
      },
      {
        field: 'budgetedHours',
        headerName: 'Budgeted Hours',
        flex: 1,
        renderCell: () => <Typography>0</Typography>, // Hardcoded count for now
      },
    )
  }

  if (isDesktop) {
    columns.push(
      {
        field: 'designerUserId',
        headerName: 'Designer',
        flex: 1,
        renderCell: (params) => {
          const project = params.row
          const user = (project as any).designerUser as User
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={user?.profileImagePath ?? ''} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
              {user?.name ?? 'Unassigned'}
            </Box>
          )
        },
      },
      {
        field: 'designStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <ProjectStatusChip status={params.value} />,
      },
    )
  }

  const summaryBlock = (
    <Grid2 container spacing={2} sx={{ mb: 2, width: '100%' }} columns={{ xs: 2, md: 4 }}>
      <Grid2 size={1}>
        <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Designs
            </Typography>
            <Typography variant="h4">99</Typography>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 size={1}>
        <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Open Designs
            </Typography>
            <Typography variant="h4">99</Typography>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 size={1}>
        <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              In Progress Designs
            </Typography>
            <Typography variant="h4">99</Typography>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 size={1}>
        <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Done Designs
            </Typography>
            <Typography variant="h4">99</Typography>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )

  return (
    <ProjectsPage
      stage={ProjectStage.design}
      title="Design Projects"
      description="Design Projects shows information about your Design Projects across all Accounts."
      buttonLabel="New Design"
      columns={columns}
      summaryBlock={summaryBlock}
    />
  )
}
