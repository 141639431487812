import { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useBuildingsCollection } from '../app/DBProvider'

interface NewBuildingModalProps {
  open: boolean
  onClose: () => void
  onCreate: (building: any) => void
  accountId: string
  siteId: string
}

export default function NewBuildingModal({
  open,
  onClose,
  onCreate,
  accountId,
  siteId,
}: Readonly<NewBuildingModalProps>) {
  const buildingsCollection = useBuildingsCollection()
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCreate = async () => {
    setIsSubmitting(true)
    try {
      const newBuilding = await buildingsCollection.insert({
        id: crypto.randomUUID(),
        orgId: '', // Set appropriate orgId
        accountId,
        siteId,
        name,
        address,
        createdDate: new Date().toISOString(),
        createdByUserId: '', // Set appropriate userId
        updatedDate: new Date().toISOString(),
        updatedByUserId: '', // Set appropriate userId
        orderNumber: 0,
        isArchived: false,
        _deleted: false,
      })
      onCreate(newBuilding)
      onClose()
    } catch (error) {
      console.error('Failed to create building:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Building</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField label="Building Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth required />
          <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={handleCreate} variant="contained" color="primary" disabled={isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
