import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { useState } from 'react'

interface NewSiteModalProps {
  open: boolean
  onClose: () => void
  onCreate: (site: { name: string; accountId: string }) => void
  accountId: string
}

export default function NewSiteModal({ open, onClose, onCreate, accountId }: Readonly<NewSiteModalProps>) {
  const [name, setName] = useState('')

  const handleCreate = () => {
    onCreate({ name, accountId })
    setName('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Site</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Site Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreate} disabled={!name}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
