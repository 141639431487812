import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridSlotsComponent,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium'
import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import NoRowsOverlay from '@/components/lib/NoRowsOverlay'
import CustomToolbar from '@/components/lib/CustomToolbar'

interface AppDataGridProps {
  rows: any[]
  columns: GridColDef[]
  emptyMessage?: string
}

export default function AppDataGrid({ rows, columns, emptyMessage }: Readonly<AppDataGridProps>): ReactElement {
  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: () => emptyMessage && <NoRowsOverlay message={emptyMessage} />,
    toolbar: CustomToolbar,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  return (
    <Box width="100%">
      <DataGridPremium
        columns={columns}
        rows={rows}
        slots={slots}
        slotProps={slotProps}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableColumnResize
        sx={{
          // this removes the drag handles
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
          // this removes the cell focus
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}`]: {
            backgroundImage: 'var(--Paper-overlay)',
          },
          border: 'none',
        }}
      />
    </Box>
  )
}
