import { useRxQuery } from 'rxdb-hooks'
import { useFavoriteTasksCollection } from '../app/DBProvider'
import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'

export default function FavoriteTasksTab({ favorite }: Readonly<{ favorite: Favorite }>) {
  const favoriteTasksCollection = useFavoriteTasksCollection()

  const { result: favoriteTasks, isFetching } = useRxQuery(
    favoriteTasksCollection.find({ selector: { favoriteId: favorite.id } }),
  )

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Favorite Task</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {favoriteTasks?.map((favoriteTask) => {
          return (
            <TableRow key={favoriteTask.id}>
              <TableCell>
                <Link href={`${favoriteTask.id}`}>{favoriteTask.name}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
