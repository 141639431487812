import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ColorMode } from './ThemeModeProvider'
import { useCookies } from 'react-cookie'

export default function ThemePicker() {
  const [cookies, setCookie] = useCookies(['colorMode'])
  const colorMode: ColorMode = (cookies.colorMode as ColorMode) || ColorMode.System

  const handleThemeChange = (event: SelectChangeEvent) => {
    const newColorMode = event.target.value as ColorMode
    setCookie('colorMode', newColorMode, { path: '/' })
  }

  return (
    <div>
      <Select value={colorMode} onChange={handleThemeChange} label="Change Theme">
        <MenuItem value={ColorMode.Light}>Light</MenuItem>
        <MenuItem value={ColorMode.Dark}>Dark</MenuItem>
        <MenuItem value={ColorMode.System}>System</MenuItem>
      </Select>
    </div>
  )
}
