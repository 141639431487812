import Ajv from 'ajv'
import { RxDocumentData, RxJsonSchema, wrappedValidateStorageFactory } from 'rxdb'
import addFormats from 'ajv-formats'

// Create an Ajv instance and add support for "date-time"
const ajv = new Ajv({
  strict: false,
})
addFormats(ajv)

export function getValidator(schema: RxJsonSchema<any>) {
  const validator = ajv.compile(schema)
  return (docData: RxDocumentData<any>) => {
    const isValid = validator(docData)
    if (isValid) {
      return []
    } else {
      return validator.errors as any
    }
  }
}

export const wrappedValidateAjvStorage = wrappedValidateStorageFactory(getValidator, 'ajv-so')
