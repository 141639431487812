import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Building } from '@/lib/schemas/BuildingSchema'
import { useBreakpoints } from '@/lib/useBreakpoints'

interface BuildingEditModalProps {
  open: boolean
  onClose: () => void
  building: Building
}

export default function BuildingEditModal({ open, onClose, building }: Readonly<BuildingEditModalProps>) {
  const { isPhone } = useBreakpoints()
  const [formData, setFormData] = useState({ ...building })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    // Handle save logic here
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isPhone}>
      <DialogTitle>
        Edit Building
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 500 }}>
          <TextField label="Building Name" name="name" value={formData.name} onChange={handleChange} fullWidth />
          <TextField label="Address" name="address" value={formData.address} onChange={handleChange} fullWidth />
          {/* Add other fields as necessary */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
