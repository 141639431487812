import React from 'react'
import { Chip } from '@mui/material'
import { statusMap } from '@/lib/utils'
import { SxProps } from '@mui/system'

interface ProjectStatusChipProps {
  status: string
  sx?: SxProps
  size?: 'small' | 'medium'
}

const ProjectStatusChip: React.FC<ProjectStatusChipProps> = ({ status, sx, size = 'medium' }) => {
  let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  switch (status) {
    case 'open':
      color = 'error'
      break
    case 'in_progress':
      color = 'success'
      break
    case 'done':
      color = 'info'
      break
    default:
      color = 'default'
  }

  return <Chip label={statusMap[status]} color={color} variant="filled" sx={sx} size={size} />
}

export default ProjectStatusChip
