import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { Site } from '@/lib/schemas/SiteSchema'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useSitesCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { useCurrentOrg } from '../app/UserProvider'
import { useBreakpoints } from '@/lib/useBreakpoints'
import CustomPagination from '../lib/CustomPagination'

export default function AccountSitesTable({
  isArchived = false,
  columns,
}: Readonly<{
  isArchived?: boolean
  columns: GridColDef<RxDocument<Site>>[]
}>) {
  const { accountId } = useParams<{ accountId: string }>()
  const sitesCollection = useSitesCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const { isPhone } = useBreakpoints()
  const navigate = useNavigate()
  const currentOrg = useCurrentOrg()

  const rxQuery = useMemo(() => {
    const query = sitesCollection
      .find()
      .where('orgId')
      .equals(currentOrg.id)
      .where('accountId')
      .equals(accountId)
      .where('isArchived')
      .equals(isArchived)
      .sort('name')

    return query
  }, [sitesCollection, accountId, isArchived, currentOrg.id])

  const { result: sites, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !sites) return
    const populateSites = async () => {
      //   for (const site of sites) {
      //     const account = await site.populate('accountId')
      //     ;(site as any).account = account
      //   }
      setIsPopulated(true)
    }
    populateSites()
  }, [isFetching, sites])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.accountId}/sites/${params.row.id}`)
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoSitesOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  if (isFetching || !sites || !isPopulated) return <></>

  return (
    <DataGridPremium
      columns={columns}
      rows={sites} // Use sites directly
      getRowId={(row) => row.id} // Add getRowId function
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick} // Add onRowClick handler only for phone screens
      getRowHeight={() => 'auto'} // Set row height to auto
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        // this removes the drag handles
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        // this removes the cell focus
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center', // Center values vertically
          paddingTop: '16px', // Add padding to the top of each row
          paddingBottom: '16px', // Add padding to the bottom of each row
        },
        [`& .${gridClasses.row}`]: {
          cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none', // Remove top border
          },
          marginLeft: -3, // Add negative left padding for small screens
          marginRight: -3, // Add negative right padding for small screens
          [`& .${gridClasses.cell}`]: {
            paddingX: 3, // Add positive right padding for small screens
            paddingY: 2, // Add positive top and bottom padding for small screens
          },
        }),
      }}
    />
  )
}

function NoSitesOverlay() {
  return <NoRowsOverlay message="No sites" />
}

function CustomToolbar() {
  const { isPhone } = useBreakpoints()

  return (
    <GridToolbarContainer
      sx={{
        ...(isPhone && {
          paddingLeft: 3, // Add positive left padding for small screens
          paddingRight: 3, // Add positive right padding for small screens
        }),
      }}
    >
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}
