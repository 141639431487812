import { Box, Typography, Button, Tabs, Tab, Divider, useTheme, IconButton, Menu, MenuItem, Link } from '@mui/material'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import NewSiteModal from './NewSiteModal'
import AccountSitesTable from './AccountSitesTable'
import { GridColDef } from '@mui/x-data-grid-premium'
import { RxDocument } from 'rxdb'
import { Site } from '@/lib/schemas/SiteSchema'
import Breadcrumbs from '../layout/Breadcrumbs'
import { useBreakpoints } from '@/lib/useBreakpoints'

export default function AccountSitesPage() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const location = useLocation()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()
  const { isTablet } = useBreakpoints()

  // Determine the tab index based on the current route
  const tabIndex = location.pathname.endsWith('/archived-sites') ? 1 : 0

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateSite = (site: any) => {
    // Navigate to the newly created site's page
    navigate(`/accounts/${site.accountId}/sites/${site.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewSiteClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  const columns: GridColDef<RxDocument<Site>>[] = [
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Link href={`/accounts/${accountId}/sites/${params.row.id}`} variant="body1">
            {params.row.name}
          </Link>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 1 }}>
            <Typography variant="body2">3 buildings | 6 plans | 38 devices | 78 % operational</Typography>
            <Box sx={{ width: '100%', display: 'flex', height: 10, mt: 1 }}>
              <Box sx={{ width: '13%', bgcolor: theme.palette.error.main, borderRadius: '5px 0 0 5px' }} />
              <Box sx={{ width: '45%', bgcolor: theme.palette.info.main }} />
              <Box sx={{ width: '42%', bgcolor: theme.palette.warning.main, borderRadius: '0 5px 5px 0' }} />
            </Box>
          </Box>
        </Box>
      ),
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'buildings',
        headerName: 'Buildings',
        flex: 1,
        renderCell: () => <Typography variant="body2">3</Typography>,
      },
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography variant="body2">6</Typography>,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography variant="body2">48</Typography>,
      },
      {
        field: 'operational',
        headerName: '% Operational',
        flex: 1,
        renderCell: () => <Typography variant="body2">78%</Typography>,
      },
      {
        field: 'progress',
        headerName: '',
        flex: 1,
        renderCell: () => (
          <Box sx={{ width: '100%', display: 'flex', height: 10 }}>
            <Box sx={{ width: '13%', bgcolor: theme.palette.error.main, borderRadius: '5px 0 0 5px' }} />
            <Box sx={{ width: '45%', bgcolor: theme.palette.info.main }} />
            <Box sx={{ width: '42%', bgcolor: theme.palette.warning.main, borderRadius: '0 5px 5px 0' }} />
          </Box>
        ),
      },
    )
  }

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">Sites</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Plan Library stores all of the Sites, Buildings, and Plans. The Plans in the Plan Library are used in the
            other areas of SiteOwl.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleOpenModal}
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
              whiteSpace: 'nowrap',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
            }}
          >
            New Site
          </Button>
          <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
            <IconButton onClick={handleMenuOpen}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleMenuNewSiteClick}>New Site</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Tabs
        value={tabIndex}
        aria-label="sites tabs"
        sx={{
          minHeight: 32, // Adjust height
          '& .MuiTab-root': {
            minHeight: 32, // Reduce tab height
            fontSize: '0.75rem', // Smaller font size
            padding: '4px 8px', // Reduce padding
          },
        }}
      >
        <Tab label="Active" component={Link} href={`/accounts/${accountId}/sites`} />
        <Tab label="Archived" component={Link} href={`/accounts/${accountId}/archived-sites`} />
      </Tabs>
      <Divider sx={{ width: '100%', mb: 2 }} /> {/* Add solid line with margin bottom */}
      {tabIndex === 0 && <AccountSitesTable isArchived={false} columns={columns} />}
      {tabIndex === 1 && <AccountSitesTable isArchived={true} columns={columns} />}
      <NewSiteModal open={isModalOpen} onClose={handleCloseModal} onCreate={handleCreateSite} accountId={accountId} />
    </>
  )
}
