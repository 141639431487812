import { DeepReadonlyObject } from 'rxdb'
import { ServiceTicketFileAttachment } from '@/lib/schemas/ServiceTicketFileAttachmentSchema'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { User } from '@/lib/schemas/UserSchema'
import _ from 'lodash'
import AppDataGrid from '@/components/lib/AppDataGrid'
import { useStorage } from '@/components/app/FirebaseProvider'
import { useEffect, useState } from 'react'
import { getDownloadURL, ref } from 'firebase/storage'

export interface ServiceTicketFilesTableProps {
  files: DeepReadonlyObject<ServiceTicketFileAttachment>[]
  users: DeepReadonlyObject<User>[]
}

type ServiceTicketFileAttachmentWithUrl = ServiceTicketFileAttachment & {
  downloadUrl: string
}

export default function ServiceTicketFilesTable({ files, users }: Readonly<ServiceTicketFilesTableProps>) {
  const [filesWithUrl, setFilesWithUrl] =
    useState<(ServiceTicketFileAttachment | ServiceTicketFileAttachmentWithUrl)[]>(files)
  const storage = useStorage()

  useEffect(() => {
    const promises: Promise<ServiceTicketFileAttachmentWithUrl>[] = _.map(
      files,
      async (file: DeepReadonlyObject<ServiceTicketFileAttachment>): Promise<ServiceTicketFileAttachmentWithUrl> => {
        const storageRef = ref(storage, file.filePath)
        const downloadUrl = await getDownloadURL(storageRef)

        return { ...file, downloadUrl }
      },
    )

    Promise.all(promises).then((comms: ServiceTicketFileAttachmentWithUrl[]) => setFilesWithUrl(comms))
  }, [storage, files])

  const columns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 2,
      renderCell: ({ row }: GridRenderCellParams) => (
        <a href={row.downloadUrl} download>
          {row.file}
        </a>
      ),
    },
    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      flex: 1,
    },
    {
      field: 'uploadedDate',
      headerName: 'Uploaded At',
      flex: 1,
    },
  ]

  const rows = _.map(filesWithUrl, (file: ServiceTicketFileAttachmentWithUrl) => ({
    id: file.id,
    file: file.filePath,
    downloadUrl: file.downloadUrl,
    uploadedBy: _.find(users, (user) => user.id === file.uploadedByUserId)?.name,
    createdDate: file.uploadedDate ? new Date(file.uploadedDate).toISOString() : 'N/A',
  }))

  return <AppDataGrid rows={rows} columns={columns} emptyMessage="No files" />
}
