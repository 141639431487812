import {
  faArrowLeft,
  faCog,
  faHome,
  faMap,
  faLocationDot,
  faPenRuler,
  faWrench,
  faChartGantt,
  faTag,
  faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import NavItem from './NavItem'

export default function AccountDrawerMenu({
  accountId,
  mobileOpen,
  handleDrawerToggle,
}: Readonly<{
  accountId: string
  mobileOpen: boolean
  handleDrawerToggle: () => void
}>) {
  return (
    <>
      <NavItem
        label="Accounts"
        icon={faArrowLeft}
        mobileOpen={mobileOpen}
        href="/accounts"
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Dashboard"
        icon={faHome}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/dashboard`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Design Projects"
        icon={faPenRuler}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/design-projects`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Installation Projects"
        icon={faWrench}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/installation-projects`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Project Devices"
        icon={faLocationDot}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/project-devices`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Live Planning"
        icon={faChartGantt}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/live-planning`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Sites"
        icon={faMap}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/sites`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Service Tickets"
        icon={faTag}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/service-tickets`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Live Devices"
        icon={faMapLocationDot}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/live-devices`}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem
        label="Settings"
        icon={faCog}
        mobileOpen={mobileOpen}
        href={`/accounts/${accountId}/settings`}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  )
}
