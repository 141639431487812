import { Box, Typography, Link, IconButton, Menu, MenuItem } from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { Floor } from '@/lib/schemas/FloorSchema'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useFloorsCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useBreakpoints } from '@/lib/useBreakpoints'
import CustomPagination from '../lib/CustomPagination'

function NoFloorsOverlay() {
  return <NoRowsOverlay message="No floors" />
}

export default function SiteFloorsTab() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const siteId = useParams<{ siteId: string }>().siteId as string
  const floorsCollection = useFloorsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const { isPhone, isTablet } = useBreakpoints()
  const navigate = useNavigate()
  const apiRef = useGridApiRef()

  const rxQuery = useMemo(() => {
    const query = floorsCollection.find().where('siteId').equals(siteId).sort('name')

    return query
  }, [floorsCollection, siteId])

  const { result: floors, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !floors) return
    const populateFloors = async () => {
      for (const floor of floors) {
        const building = await floor.populate('buildingId')
        ;(floor as any).buildingName = building.name
      }
      setIsPopulated(true)
    }
    populateFloors()
  }, [isFetching, floors])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(
        `/accounts/${params.row.accountId}/sites/${siteId}/buildings/${params.row.buildingId}/floors/${params.row.id}`,
      )
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoFloorsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  const columns: GridColDef<RxDocument<Floor>>[] = [
    {
      field: 'planImagePath',
      headerName: 'Thumbnail',
      flex: 1,
      renderCell: (params) => {
        if (!params.row.planImagePath) return null
        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
              src={`${import.meta.env.VITE_IMGIX_BASE_URL}/${params.row.planImagePath}`}
              alt={params.row.name}
              style={{ width: '75px', height: '75px', objectFit: 'cover' }}
            />
          </Box>
        )
      },
    },
    {
      field: 'buildingName',
      headerName: 'Building',
      flex: 2,
      renderCell: (params) => {
        return <Typography variant="body2">{params.value}</Typography>
      },
    },
    {
      field: 'name',
      headerName: 'Plan Name',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Link
            href={`/accounts/${accountId}/sites/${siteId}/buildings/${params.row.buildingId}/floors/${params.row.id}`}
            variant="body1"
          >
            {params.row.name}
          </Link>
          {isPhone && (
            <Typography variant="body2" color="textSecondary">
              6 Plans | 48 Devices | 23 Tasks | 55 Budgeted Hours
            </Typography>
          )}
        </Box>
      ),
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography variant="body2">6</Typography>,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography variant="body2">48</Typography>,
      },
      {
        field: 'tasks',
        headerName: 'Tasks',
        flex: 1,
        renderCell: () => <Typography variant="body2">23</Typography>,
      },
      {
        field: 'bugetedHours',
        headerName: 'Budgeted Hours',
        flex: 2,
        renderCell: () => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
              55 Budgeted Hours
            </Typography>
          </Box>
        ),
      },
    )
  }

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['buildingName'],
      },
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
      },
      pagination: { paginationModel: { pageSize: 25 } },
    },
  })

  return (
    <DataGridPremium
      columns={columns}
      rows={floors}
      getRowId={(row) => row.id}
      apiRef={apiRef}
      loading={!isPopulated}
      initialState={initialState}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick}
      getRowHeight={() => 'auto'}
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: '16px',
          paddingBottom: '16px',
        },
        [`& .${gridClasses.row}`]: {
          cursor: { xs: 'pointer', md: 'default' },
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none',
          },
          marginLeft: -3,
          marginRight: -3,
          [`& .${gridClasses.cell}`]: {
            paddingX: 3,
            paddingY: 2,
          },
        }),
      }}
    />
  )
}

function CustomToolbar() {
  const { isPhone } = useBreakpoints()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...(isPhone && {
          paddingLeft: 3,
          paddingRight: 3,
        }),
      }}
    >
      <GridToolbarQuickFilter />
      <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
        <IconButton onClick={handleMenuOpen}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>New Floor</MenuItem>
        </Menu>
      </Box>
    </GridToolbarContainer>
  )
}
