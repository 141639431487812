import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const groupMemberSchemaLiteral = {
  title: 'Group Member Schema',
  describe: 'Describes a group member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 100 },
    groupId: { type: 'string', ref: 'groups' },
    userId: { type: 'string', ref: 'users' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'groupId', 'userId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(groupMemberSchemaLiteral)

// aggregate the document type from the schema
export type GroupMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const groupMemberSchema: RxJsonSchema<GroupMember> = groupMemberSchemaLiteral

export type GroupMemberDocument = RxDocument<GroupMember>

export type GroupMemberCollection = RxCollection<GroupMember>
