import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function FavoriteInfoTab({ favorite }: Readonly<{ favorite: Favorite }>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Field</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">ID</TableCell>
          <TableCell>{favorite.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell>{favorite.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Primary Device ID</TableCell>
          <TableCell>{favorite.primaryDeviceId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Primary Task ID</TableCell>
          <TableCell>{favorite.primaryTaskId}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
