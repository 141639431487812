import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const serviceTicketWatcherSchemaLiteral = {
  title: 'Service Ticket Watcher Schema',
  describe: 'Describes a service ticket watcher',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
      ref: 'orgs',
    },
    accountId: {
      type: 'string',
      ref: 'accounts',
    },
    serviceTicketId: {
      type: 'string',
      ref: 'serviceTickets',
    },
    userId: {
      type: 'string',
      ref: 'users',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'serviceTicketId', 'userId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(serviceTicketWatcherSchemaLiteral)

// aggregate the document type from the schema
export type ServiceTicketWatcher = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const serviceTicketWatcherSchema: RxJsonSchema<ServiceTicketWatcher> = serviceTicketWatcherSchemaLiteral

export type ServiceTicketWatcherDocument = RxDocument<ServiceTicketWatcher>

export type ServiceTicketWatcherCollection = RxCollection<ServiceTicketWatcher>
