import { AccountMember, AccountMemberCollection, accountMemberSchema } from '@/lib/schemas/AccountMemberSchema'
import { Account, AccountCollection, accountSchema } from '@/lib/schemas/AccountSchema'
import { Building, BuildingCollection, buildingSchema } from '@/lib/schemas/BuildingSchema'
import { DeviceImage, DeviceImageCollection, deviceImageSchema } from '@/lib/schemas/DeviceImageSchema'
import { Device, DeviceCollection, deviceSchema } from '@/lib/schemas/DeviceSchema'
import { DeviceVersion, DeviceVersionCollection, deviceVersionSchema } from '@/lib/schemas/DeviceVersionSchema'
import {
  EquipmentTypeCategory,
  EquipmentTypeCategoryCollection,
  equipmentTypeCategorySchema,
} from '@/lib/schemas/EquipmentTypeCategorySchema'
import { EquipmentType, EquipmentTypeCollection, equipmentTypeSchema } from '@/lib/schemas/EquipmentTypeSchema'
import { FavoriteDevice, FavoriteDeviceCollection, favoriteDeviceSchema } from '@/lib/schemas/FavoriteDeviceSchema'
import { Favorite, FavoriteCollection, favoriteSchema } from '@/lib/schemas/FavoriteSchema'
import { FavoriteTask, FavoriteTaskCollection, favoriteTaskSchema } from '@/lib/schemas/FavoriteTaskSchema'
import { Floor, FloorCollection, floorSchema } from '@/lib/schemas/FloorSchema'
import { groupMemberSchema } from '@/lib/schemas/GroupMemberSchema'
import { groupSchema } from '@/lib/schemas/GroupSchema'
import { Location, LocationCollection, locationSchema } from '@/lib/schemas/LocationSchema'
import { OrgMember, OrgMemberCollection, orgMemberSchema } from '@/lib/schemas/OrgMemberSchema'
import { Org, OrgCollection, orgSchema } from '@/lib/schemas/OrgSchema'
import {
  ProjectFileAttachment,
  ProjectFileAttachmentCollection,
  projectFileAttachmentSchema,
} from '@/lib/schemas/ProjectFileAttachmentSchema'
import { ProjectFloor, ProjectFloorCollection, projectFloorSchema } from '@/lib/schemas/ProjectFloorSchema'
import { ProjectMember, ProjectMemberCollection, projectMemberSchema } from '@/lib/schemas/ProjectMemberSchema'
import { Project, ProjectCollection, projectSchema } from '@/lib/schemas/ProjectSchema'
import {
  ServiceTicketComment,
  ServiceTicketCommentCollection,
  serviceTicketCommentSchema,
} from '@/lib/schemas/ServiceTicketCommentSchema'
import {
  ServiceTicketFileAttachment,
  ServiceTicketFileAttachmentCollection,
  serviceTicketFileAttachmentSchema,
} from '@/lib/schemas/ServiceTicketFileAttachmentSchema'
import { ServiceTicket, ServiceTicketCollection, serviceTicketSchema } from '@/lib/schemas/ServiceTicketSchema'
import {
  ServiceTicketWatcher,
  ServiceTicketWatcherCollection,
  serviceTicketWatcherSchema,
} from '@/lib/schemas/ServiceTicketWatcherSchema'
import { SiteFileAttachmentCollection, siteFileAttachmentSchema } from '@/lib/schemas/SiteFileAttachmentSchema'
import { SiteMember, SiteMemberCollection, siteMemberSchema } from '@/lib/schemas/SiteMemberSchema'
import { Site, SiteCollection, siteSchema } from '@/lib/schemas/SiteSchema'
import { TaskImage, TaskImageCollection, taskImageSchema } from '@/lib/schemas/TaskImageSchema'
import { Task, TaskCollection, taskSchema } from '@/lib/schemas/TaskSchema'
import { TaskType, TaskTypeCollection, taskTypeSchema } from '@/lib/schemas/TaskTypeSchema'
import {
  UserAccountPreferences,
  UserAccountPreferencesCollection,
  userAccountPreferencesSchema,
} from '@/lib/schemas/UserAccountPreferencesSchema'
import { UserDeviceToken, UserDeviceTokenCollection, userDeviceTokenSchema } from '@/lib/schemas/UserDeviceTokenSchema'
import {
  UserFloorPreferences,
  UserFloorPreferencesCollection,
  userFloorPreferencesSchema,
} from '@/lib/schemas/UserFloorPreferencesSchema'
import { User, UserCollection, userSchema } from '@/lib/schemas/UserSchema'
import { wrappedValidateAjvStorage } from '@/lib/validate-ajv-so'
import { SQLiteConnection, CapacitorSQLite } from '@capacitor-community/sqlite'
import { Capacitor } from '@capacitor/core'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { addRxPlugin, createRxDatabase, RxCollection, RxDatabase, RxStorage } from 'rxdb'
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import { getRxStorageSQLite, getSQLiteBasicsCapacitor } from 'rxdb-premium/plugins/storage-sqlite'
import { setPremiumFlag } from 'rxdb-premium/plugins/shared'
import { disableWarnings, RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'

// we paid for premium RxDb.  so turn off warnings a restrictions
setPremiumFlag()

// don't show the dev mode warning in dev because we expect them
if (process.env.NODE_ENV === 'development') {
  disableWarnings()
  addRxPlugin(RxDBDevModePlugin)
}

addRxPlugin(RxDBLocalDocumentsPlugin)
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

export type SiteOwlCollections = {
  buildings: BuildingCollection
  account_members: AccountMemberCollection
  accounts: AccountCollection
  device_images: DeviceImageCollection
  devices: DeviceCollection
  device_versions: DeviceVersionCollection
  equipment_type_categories: EquipmentTypeCategoryCollection
  equipment_types: EquipmentTypeCollection
  favorite_devices: FavoriteDeviceCollection
  favorite_tasks: FavoriteTaskCollection
  favorites: FavoriteCollection
  floors: FloorCollection
  locations: LocationCollection
  org_members: OrgMemberCollection
  orgs: OrgCollection
  project_files: ProjectFileAttachmentCollection
  project_floors: ProjectFloorCollection
  project_members: ProjectMemberCollection
  projects: ProjectCollection
  service_ticket_comments: ServiceTicketCommentCollection
  service_ticket_file_attachments: ServiceTicketFileAttachmentCollection
  service_ticket_watchers: ServiceTicketWatcherCollection
  service_tickets: ServiceTicketCollection
  site_files: SiteFileAttachmentCollection
  site_members: SiteMemberCollection
  sites: SiteCollection
  task_images: TaskImageCollection
  task_types: TaskTypeCollection
  tasks: TaskCollection
  users: UserCollection
  user_account_preferences: UserAccountPreferencesCollection
  user_device_tokens: UserDeviceTokenCollection
  user_floor_preferences: UserFloorPreferencesCollection
}

export type SiteOwlDatabase = RxDatabase<SiteOwlCollections>

type DBState = {
  db?: SiteOwlDatabase
  dbIsReady: boolean
}

export const DBContext = createContext<DBState>({ db: undefined, dbIsReady: false })

export default function DBProvider({
  defaultDb = undefined,
  defaultDbIsReady = false,
  children,
}: Readonly<{
  defaultDb?: SiteOwlDatabase
  defaultDbIsReady?: boolean
  children: React.ReactNode
}>) {
  const [db, setDb] = useState<SiteOwlDatabase | undefined>(defaultDb)
  const [dbIsReady, setDbIsReady] = useState(defaultDbIsReady)

  useEffect(() => {
    if (db) return

    const setupDb = async () => {
      const storage = initializeStorage()
      const newDb = await createDb(storage)
      await initializeCollections(newDb)
      setDb(newDb)
      setDbIsReady(true)
    }

    setupDb()
  }, [db])

  console.log(`DBProvider: rendering db=${db} dbIsReady=${dbIsReady}`)

  const value = useMemo(() => {
    return { db, dbIsReady }
  }, [db, dbIsReady])

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>
}

const initializeStorage = (): RxStorage<any, any> => {
  console.log('DBProvider: create the rxdb storage')

  let storage: RxStorage<any, any>
  if (Capacitor.isNativePlatform()) {
    // if in a native app, use the sqlite storage
    console.log('initializing the sqlite storage')
    const sqlite = new SQLiteConnection(CapacitorSQLite)
    storage = getRxStorageSQLite({
      sqliteBasics: getSQLiteBasicsCapacitor(sqlite, Capacitor),
    })
  } else {
    console.log('initializing the indexeddb storage')
    // otherwise use the IndexDB storage for the web browser
    storage = getRxStorageIndexedDB()
  }

  // if we are in the dev environment, wrap the storage with the validateAjvStorage
  // for additional runntime validation and checks
  if (import.meta.env.VITE_NODE_ENV === 'development') {
    console.log('wrapping storage with validateAjvStorage because we are in development')
    storage = wrappedValidateAjvStorage({
      storage,
    })
  }

  return storage
}

const createDb = async (storage: RxStorage<any, any>): Promise<SiteOwlDatabase> => {
  console.log('DBProvider: creating the rxdb database')
  return await createRxDatabase<SiteOwlCollections>({
    name: 'siteowlrxdb',
    storage,
    multiInstance: true,
    eventReduce: true,
    ignoreDuplicate: process.env.NODE_ENV === 'development',
    localDocuments: true,
  })
}

export const initializeCollections = async (database: SiteOwlDatabase) => {
  console.log('DBProvider: db is created, so now add collections')

  await database.addCollections({
    buildings: {
      schema: buildingSchema,
      localDocuments: true,
    },
    account_members: {
      schema: accountMemberSchema,
      localDocuments: true,
    },
    accounts: {
      schema: accountSchema,
      localDocuments: true,
    },
    device_images: {
      schema: deviceImageSchema,
      localDocuments: true,
    },
    devices: {
      schema: deviceSchema,
      localDocuments: true,
    },
    device_versions: {
      schema: deviceVersionSchema,
      localDocuments: true,
    },
    equipment_type_categories: {
      schema: equipmentTypeCategorySchema,
      localDocuments: true,
    },
    equipment_types: {
      schema: equipmentTypeSchema,
      localDocuments: true,
    },
    favorite_devices: {
      schema: favoriteDeviceSchema,
      localDocuments: true,
    },
    favorite_tasks: {
      schema: favoriteTaskSchema,
      localDocuments: true,
    },
    favorites: {
      schema: favoriteSchema,
      localDocuments: true,
    },
    floors: {
      schema: floorSchema,
      localDocuments: true,
    },
    groups: {
      schema: groupSchema,
      localDocuments: true,
    },
    group_members: {
      schema: groupMemberSchema,
      localDocuments: true,
    },
    locations: {
      schema: locationSchema,
      localDocuments: true,
    },
    org_members: {
      schema: orgMemberSchema,
      localDocuments: true,
    },
    orgs: {
      schema: orgSchema,
      localDocuments: true,
    },
    project_files: {
      schema: projectFileAttachmentSchema,
      localDocuments: true,
    },
    project_floors: {
      schema: projectFloorSchema,
      localDocuments: true,
    },
    project_members: {
      schema: projectMemberSchema,
      localDocuments: true,
    },
    projects: {
      schema: projectSchema,
      localDocuments: true,
    },
    service_ticket_comments: {
      schema: serviceTicketCommentSchema,
      localDocuments: true,
    },
    service_ticket_file_attachments: {
      schema: serviceTicketFileAttachmentSchema,
      localDocuments: true,
    },
    service_ticket_watchers: {
      schema: serviceTicketWatcherSchema,
      localDocuments: true,
    },
    service_tickets: {
      schema: serviceTicketSchema,
      localDocuments: true,
    },
    site_files: {
      schema: siteFileAttachmentSchema,
      localDocuments: true,
    },
    site_members: {
      schema: siteMemberSchema,
      localDocuments: true,
    },
    sites: {
      schema: siteSchema,
      localDocuments: true,
    },
    task_images: {
      schema: taskImageSchema,
      localDocuments: true,
    },
    task_types: {
      schema: taskTypeSchema,
      localDocuments: true,
    },
    tasks: {
      schema: taskSchema,
      localDocuments: true,
    },
    users: {
      schema: userSchema,
      localDocuments: true,
    },
    user_account_preferences: {
      schema: userAccountPreferencesSchema,
      localDocuments: true,
    },
    user_device_tokens: {
      schema: userDeviceTokenSchema,
      localDocuments: true,
    },
    user_floor_preferences: {
      schema: userFloorPreferencesSchema,
      localDocuments: true,
    },
  })
}

export const useDBState = () => {
  return useContext(DBContext)
}

export const useDB = (): SiteOwlDatabase => {
  const db = useContext(DBContext).db
  if (!db) throw new Error('database not configured')
  return db
}

export const useAccountsCollection = (): RxCollection<Account> => {
  const db = useDB()
  return db.accounts
}

export const useUsersCollection = (): RxCollection<User> => {
  const db = useDB()
  return db.users
}

export const useOrgsCollection = (): RxCollection<Org> => {
  const db = useDB()
  return db.orgs
}

export const useOrgMembersCollection = (): RxCollection<OrgMember> => {
  const db = useDB()
  return db.org_members
}

export const useBuildingsCollection = (): RxCollection<Building> => {
  const db = useDB()
  return db.buildings
}

export const useAccountMembersCollection = (): RxCollection<AccountMember> => {
  const db = useDB()
  return db.account_members
}

export const useDeviceImagesCollection = (): RxCollection<DeviceImage> => {
  const db = useDB()
  return db.device_images
}

export const useDevicesCollection = (): RxCollection<Device> => {
  const db = useDB()
  return db.devices
}

export const useDeviceVersionsCollection = (): RxCollection<DeviceVersion> => {
  const db = useDB()
  return db.device_versions
}

export const useEquipmentTypeCategoriesCollection = (): RxCollection<EquipmentTypeCategory> => {
  const db = useDB()
  return db.equipment_type_categories
}

export const useEquipmentTypesCollection = (): RxCollection<EquipmentType> => {
  const db = useDB()
  return db.equipment_types
}

export const useFavoriteDevicesCollection = (): RxCollection<FavoriteDevice> => {
  const db = useDB()
  return db.favorite_devices
}

export const useFavoriteTasksCollection = (): RxCollection<FavoriteTask> => {
  const db = useDB()
  return db.favorite_tasks
}

export const useFavoritesCollection = (): RxCollection<Favorite> => {
  const db = useDB()
  return db.favorites
}

export const useFloorsCollection = (): RxCollection<Floor> => {
  const db = useDB()
  return db.floors
}

export const useLocationsCollection = (): RxCollection<Location> => {
  const db = useDB()
  return db.locations
}

export const useProjectFileAttachmentsCollection = (): RxCollection<ProjectFileAttachment> => {
  const db = useDB()
  return db.project_files
}

export const useProjectFloorsCollection = (): RxCollection<ProjectFloor> => {
  const db = useDB()
  return db.project_floors
}

export const useProjectMembersCollection = (): RxCollection<ProjectMember> => {
  const db = useDB()
  return db.project_members
}

export const useProjectsCollection = (): RxCollection<Project> => {
  const db = useDB()
  return db.projects
}

export const useServiceTicketCommentsCollection = (): RxCollection<ServiceTicketComment> => {
  const db = useDB()
  return db.service_ticket_comments
}

export const useServiceTicketFileAttachmentsCollection = (): RxCollection<ServiceTicketFileAttachment> => {
  const db = useDB()
  return db.service_ticket_file_attachments
}

export const useServiceTicketWatchersCollection = (): RxCollection<ServiceTicketWatcher> => {
  const db = useDB()
  return db.service_ticket_watchers
}

export const useServiceTicketsCollection = (): RxCollection<ServiceTicket> => {
  const db = useDB()
  return db.service_tickets
}

export const useSiteMembersCollection = (): RxCollection<SiteMember> => {
  const db = useDB()
  return db.site_members
}

export const useSitesCollection = (): RxCollection<Site> => {
  const db = useDB()
  return db.sites
}

export const useTaskImagesCollection = (): RxCollection<TaskImage> => {
  const db = useDB()
  return db.task_images
}

export const useTaskTypesCollection = (): RxCollection<TaskType> => {
  const db = useDB()
  return db.task_types
}

export const useTasksCollection = (): RxCollection<Task> => {
  const db = useDB()
  return db.tasks
}

export const useUserAccountPreferencesCollection = (): RxCollection<UserAccountPreferences> => {
  const db = useDB()
  return db.user_account_preferences
}

export const useUserDeviceTokensCollection = (): RxCollection<UserDeviceToken> => {
  const db = useDB()
  return db.user_device_tokens
}

export const useUserFloorPreferencesCollection = (): RxCollection<UserFloorPreferences> => {
  const db = useDB()
  return db.user_floor_preferences
}
