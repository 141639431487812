import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum AccountMemberRole {
  administrator = 'administrator',
  live_site_agent = 'live_site_agent',
  live_site_manager = 'live_site_manager',
  live_site_technician = 'live_site_technician',
  live_site_view_all = 'live_site_view_all',
  live_site_ticket_submitter = 'live_site_ticket_submitter',
  project_lead_technician = 'project_lead_technician',
  project_manager = 'project_manager',
  project_technician = 'project_technician',
  project_view_all = 'project_view_all',
}

export enum AccountMemberLiveSiteRole {
  agent = 'agent',
  manager = 'manager',
  technician = 'technician',
  view_all = 'view_all',
}

export enum AccountMemberProjectRole {
  lead_technician = 'lead_technician',
  manager = 'manager',
  technician = 'technician',
  view_all = 'view_all',
}

const accountMemberSchemaLiteral = {
  title: 'Account Member Schema',
  describe: 'Describes a account member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string', ref: 'orgs' },
    accountId: { type: 'string', ref: 'accounts' },
    userId: { type: 'string', ref: 'users' },
    roles: { type: 'array', items: { type: 'string', enum: Object.values(AccountMemberRole) } },
    employer: { type: 'string' },
    emailReport: { type: 'boolean' },
    deviceAlert: { type: 'boolean' },
    lastActiveDate: { type: 'string', format: 'date-time' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'userId', 'roles', 'emailReport', 'deviceAlert', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(accountMemberSchemaLiteral)

// aggregate the document type from the schema
export type AccountMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const accountMemberSchema: RxJsonSchema<AccountMember> = accountMemberSchemaLiteral

export type AccountMemberDocument = RxDocument<AccountMember>

export type AccountMemberCollection = RxCollection<AccountMember>
