import { useCallback, useState, useEffect } from 'react'
import { useDBState } from '@/components/app/DBProvider'

interface ModalProps {
  modalIsOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export function useModal(): ModalProps {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false)

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])

  return {
    modalIsOpen,
    openModal,
    closeModal,
  }
}

export function useSetting<T>(id: string, defaultValue: T): [T, (value: T) => Promise<void>] {
  const { db, dbIsReady } = useDBState()
  const [value, setValue] = useState<T>(defaultValue)

  // Load initial value and subscribe to changes
  useEffect(() => {
    if (!db || !dbIsReady) return

    const loadValue = async () => {
      try {
        const doc = await db.getLocal<{ value: T }>(id)
        if (doc) {
          setValue(doc.get('value'))
        } else {
          // Initialize with default value if document doesn't exist
          await db.insertLocal(id, { value: defaultValue })
        }
      } catch (error) {
        console.error(`Error loading setting ${id}:`, error)
      }
    }

    // Subscribe to changes in the local document
    const subscription = db.$.subscribe((changeEvent) => {
      if (changeEvent.documentId === id) {
        const doc = changeEvent.documentData as { value: T }
        if (doc) {
          setValue(doc.value)
        }
      }
    })

    loadValue()

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe()
    }
  }, [db, dbIsReady, id, defaultValue])

  // Update function that persists to local document
  const setValueAndPersist = useCallback(
    async (newValue: T) => {
      if (!db || !dbIsReady) return

      try {
        await db.upsertLocal(id, { value: newValue })
      } catch (error) {
        console.error(`Error saving setting ${id}:`, error)
      }
    },
    [db, dbIsReady, id],
  )

  return [value, setValueAndPersist]
}
