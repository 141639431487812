import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { DeepReadonlyObject } from 'rxdb'
import { Box, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useRxQuery } from 'rxdb-hooks'
import {
  useServiceTicketWatchersCollection,
  useServiceTicketCommentsCollection,
  useUsersCollection,
} from '../app/DBProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import ServiceTicketWatchersTable from './components/ServiceTicketWatchersTable'
import ServiceTicketCommentsTable from './components/ServiceTicketCommentsTable'

export default function ServiceTicketInfoTab({
  serviceTicket,
}: Readonly<{ serviceTicket: DeepReadonlyObject<ServiceTicket> }>) {
  const serviceTicketWatchersCollection = useServiceTicketWatchersCollection()
  const serviceTicketCommentsCollection = useServiceTicketCommentsCollection()
  const usersCollection = useUsersCollection()

  // Watchers data
  const { result: watchers, isFetching: isFetchingWatchers } = useRxQuery(
    serviceTicketWatchersCollection.find({ selector: { serviceTicketId: serviceTicket.id } }),
  )
  const watcherUserIds = watchers.map((watcher) => watcher.userId)
  const { result: watcherUsers, isFetching: isFetchingWatcherUsers } = useRxQuery(
    usersCollection.find({ selector: { id: { $in: watcherUserIds } } }),
  )

  // Comments data
  const { result: comments, isFetching: isFetchingComments } = useRxQuery(
    serviceTicketCommentsCollection.find({ selector: { serviceTicketId: serviceTicket.id } }),
  )
  const commentUserIds = _.map(comments, (comment) => comment.createdByUserId)
  const { result: commentUsers, isFetching: isFetchingCommentUsers } = useRxQuery(
    usersCollection.find({ selector: { id: { $in: commentUserIds } } }),
  )

  if (isFetchingWatchers || isFetchingWatcherUsers || isFetchingComments || isFetchingCommentUsers) return <></>

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell>{serviceTicket.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Subject</TableCell>
            <TableCell>{serviceTicket.subject}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Requested By User ID</TableCell>
            <TableCell>{serviceTicket.requestedByUserId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Status</TableCell>
            <TableCell>{serviceTicket.status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Priority</TableCell>
            <TableCell>{serviceTicket.priority}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Floor ID</TableCell>
            <TableCell>{serviceTicket.floorId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Description</TableCell>
            <TableCell>{serviceTicket.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Assigned To User ID</TableCell>
            <TableCell>{serviceTicket.assignedToUserId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Due Date</TableCell>
            <TableCell>{serviceTicket.dueDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Closed Date</TableCell>
            <TableCell>{serviceTicket.closedDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Cost</TableCell>
            <TableCell>{serviceTicket.cost}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Is Archived</TableCell>
            <TableCell>{serviceTicket.isArchived}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Scheduled Date</TableCell>
            <TableCell>{serviceTicket.scheduledDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Device IDs</TableCell>
            <TableCell>{serviceTicket.deviceIds}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Quote Prepared For</TableCell>
            <TableCell>{serviceTicket.quote?.preparedFor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Quote Prepared By</TableCell>
            <TableCell>{serviceTicket.quote?.preparedBy}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Quote Tax Percentage</TableCell>
            <TableCell>{serviceTicket.quote?.taxPercentage}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Watchers</Typography>
          <Button variant="outlined" color="primary" size="small" startIcon={<FontAwesomeIcon icon={faPlus} />}>
            Add Watcher
          </Button>
        </Box>
        <ServiceTicketWatchersTable users={watcherUsers} />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Comments
        </Typography>
        <ServiceTicketCommentsTable comments={comments} users={commentUsers} />
      </Box>
    </>
  )
}
