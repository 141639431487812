import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const buildingSchemaLiteral = {
  title: 'Building Schema',
  describe: 'Describes a building',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string', ref: 'orgs' },
    accountId: { type: 'string', ref: 'accounts' },
    siteId: { type: 'string', ref: 'sites' },
    name: { type: 'string' },
    address: { type: 'string' },
    createdDate: { type: 'string', format: 'date-time' },
    createdByUserId: { type: 'string', ref: 'users' },
    updatedDate: { type: 'string', format: 'date-time' },
    updatedByUserId: { type: 'string', ref: 'users' },
    orderNumber: { type: 'number' },
    isArchived: { type: 'boolean' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'siteId', 'name', 'orderNumber', 'isArchived', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaTyped = toTypedRxJsonSchema(buildingSchemaLiteral)

// aggregate the document type from the schema
export type Building = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const buildingSchema: RxJsonSchema<Building> = buildingSchemaLiteral

export type BuildingDocument = RxDocument<Building>

export type BuildingCollection = RxCollection<Building>
